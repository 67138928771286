import { createSlice } from "@reduxjs/toolkit";
import { ExpeditionStateType } from "./interface";

const initialState: ExpeditionStateType = {
    expeditions: null,
    isOpenModal: false,
    isOpenRewardModal: false,
    isOpenAdsModal: false,
    friends: null,
    modalRewardData: null,
    isValidate: false,
    selectedExpedition: null,
    license: null,
};

export const expeditionSlice = createSlice({
    name: "expeditionSlice",
    initialState,
    reducers: {
        setExpeditions: (state, action) => {
            state.expeditions = action.payload.expeditions;
        },
        setIsModalAdsOpen: (state, action) => {
            state.isOpenAdsModal = action.payload;
        },
        setLicenseData: (state, action) => {
            state.license = action.payload.item;
        },
        setRewardModal: (state, action) => {
            state.modalRewardData = action.payload;
        },
        setIsOpenRewardModal: (state, action) => {
            state.isOpenRewardModal = action.payload;
        },
        setExpeditionType: (state, action) => {
            state.selectedExpedition = action.payload;
        },
        setFriends: (state, action) => {
            state.friends = action.payload;
        },
        setSelectedFriend: (state, action) => {
            const selectedFriend = state.friends?.find(
                (friend) => friend.id == action.payload
            );
            if (selectedFriend) {
                selectedFriend.isSelected = !selectedFriend?.isSelected;
            }
        },
        clearExpeditions: (state) => {
            state.expeditions = null;
        },
        setModalInviteIsOpen: (state, action) => {
            state.isOpenModal = action.payload;
        },
    },
});

export const {
    setLicenseData,
    setExpeditions,
    clearExpeditions,
    setModalInviteIsOpen,
    setFriends,
    setExpeditionType,
    setIsModalAdsOpen,
    setSelectedFriend,
    setRewardModal,
    setIsOpenRewardModal,
} = expeditionSlice.actions;
export default expeditionSlice.reducer;
