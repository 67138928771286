import { createSlice } from "@reduxjs/toolkit";
import { IUserSlice } from "./interfaces";
import { USER } from "./user.mockData";
import { Address } from "@ton/ton";
import { isTestnet } from "../../../localConfig";

const initialState: IUserSlice = USER;

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.id = action.payload.user_id;
      state.ref = action.payload.start_param;
      state.username = action.payload.username;
      state.is_premium = action.payload.is_premium;
      state.url = action.payload.url;
    },
    setMapPiece: (state, action) => {
      state.balance.map = action.payload;
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload?.transactions;
    },
    addTranscation: (state, action) => {
      state.transactions?.unshift(action.payload);
    },
    setStats: (state, action) => {
      state.stats = action.payload;
    },
    setSuperBooster: (state, action) => {
      state.balance.super_boster = action.payload;
    },
    setBoosterID: (state, action) => {
      state.balance.multipliers_boosters.boosterID = action.payload;
    },
    decreaseBoosterTime: (state, action) => {
      const remainingTime = action.payload.time_remaing;
      if (state.balance.multipliers_boosters) {
        state.balance.multipliers_boosters.time_remaing = remainingTime;
      }
    },

    setBoosters: (state, action) => {
      state.balance.auto_boosters.quantity =
        action.payload.auto_boosters.quantity || 0;
      state.balance.multipliers_boosters.booster =
        action.payload.multipliers_boosters.booster || 0;
      state.balance.multipliers_boosters.time_remaing =
        action.payload.multipliers_boosters.time_remaing || "";
      state.balance.multipliers_boosters.count =
        action.payload.multipliers_boosters.count || 0;
      state.balance.multipliers_boosters.allCount =
        action.payload.multipliers_boosters.allCount || 0;
    },

    resetBooster: (state, action) => {
      const { booster } = action.payload;
      if (state.balance.multipliers_boosters) {
        state.balance.multipliers_boosters.booster = booster;
        state.balance.multipliers_boosters.time_remaing = 0;
      }
    },

    updateBalance: (state, action) => {
      state.balance.coins = action.payload.coins;
      state.balance.pearls = action.payload.pearls;
      state.balance.keys_cheast = action.payload.keys_chest;
      state.balance.ton = action.payload.ton;
      state.balance.map = action.payload.map;
    },

    increaseCoins: (state, action) => {
      state.balance.coins += action.payload;
    },

    increasePearls: (state, action) => {
      state.balance.pearls += action.payload;
    },

    setCanContinueMultiplier: (state, action) => {
      if (action.payload !== undefined) {
        state.canContinueMultiplier = action.payload;
      } else {
        state.canContinueMultiplier = true;
      }
    },

    increaseAutoBoostersUser: (state, action) => {
      state.balance.auto_boosters.quantity += action.payload;
    },

    increaseKeysChests: (state, action) => {
      state.balance.keys_cheast += action.payload;
    },

    decreaseAutoBoostersUser: (state, action) => {
      state.balance.auto_boosters.quantity -= action.payload;
    },

    decreaseCoins: (state, action) => {
      state.balance.coins -= action.payload;
    },

    decreasePearls: (state, action) => {
      state.balance.pearls -= action.payload;
    },

    decreaseKeysChests: (state, action) => {
      state.balance.keys_cheast -= action.payload;
    },

    setCheasts: (state, action) => {
      state.chests = action.payload;
    },

    setWallet: (state, action) => {
      if (action.payload?.account) {
        state.wallet = {
          ...action.payload,
          account: {
            ...action.payload.account,
            publicKey: Address.parse(action.payload.account.address).toString({
              urlSafe: true,
              bounceable: false,
              testOnly: isTestnet ? true : false,
            }),
          },
        };
      } else {
        state.wallet = null;
      }
    },
    setAdress: (state, action) => {
      if (state.wallet) {
        state.wallet.account.address = action.payload;
      } else {
        state.wallet = {
          account: {
            address: action.payload,
            chain: "",
            publicKey: "",
            walletStateInit: "",
          },
          aboutUrl: "",
          appName: "",
          bridgeUrl: "",
          deepLink: "",
          device: {
            platform: "",
            appName: "",
            appVersion: "",
            maxProtocolVersion: 0,
            features: [],
          },
          imageUrl: "",
          name: "",
          openMethod: "",
          platforms: [""],
          provider: {
            provider: "",
            bridgeUrl: "",
          },
          tondns: "",
          universalLink: "",
        };
      }
    },

    setFirstEperience: (state, action) => {
      state.firstExperience = action.payload;
    },
    setLicenseCapitanUser: (state, action) => {
      state.license_capitan = action.payload.license_capitan;
      state.license_capitan_expires=action.payload.license_capitan_expires;
    },
  },
});

export const {
  setUser,
  increaseCoins,
  increasePearls,
  decreaseCoins,
  setCanContinueMultiplier,
  setWallet,
  increaseAutoBoostersUser,
  setMapPiece,
  decreaseAutoBoostersUser,
  decreasePearls,
  updateBalance,
  setLicenseCapitanUser,
  setCheasts,
  setBoosterID,
  increaseKeysChests,
  setBoosters,
  setSuperBooster,
  setTransactions,
  addTranscation,
  setStats,
  setAdress,
  decreaseKeysChests,
  decreaseBoosterTime,
  resetBooster,
  setFirstEperience,
} = userSlice.actions;
export default userSlice.reducer;
