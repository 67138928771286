import { StaticImageData } from "next/image";
import { getLocalStorageItem } from "./localStorage";
import tg from "@/assets/screens/friends/tg-base.png";
import tg_en from "@/assets/screens/friends/tg_base_en.png";
import tg_fr from "@/assets/screens/friends/tg_base_fr.png";
import tg_es from "@/assets/screens/friends/tg_base_es.png";
import tg_id from "@/assets/screens/friends/tg_base_id.png";
import tg_pt from "@/assets/screens/friends/tg_base_pt.png";
import tgPremium from "@/assets/screens/friends/tg-prem.png";
import tgPremium_en from "@/assets/screens/friends/tg_prem_en.png";
import tgPremium_fr from "@/assets/screens/friends/tg_prem_fr.png";
import tgPremium_es from "@/assets/screens/friends/tg_prem_es.png";
import tgPremium_id from "@/assets/screens/friends/tg_prem_id.png";
import tgPremium_pt from "@/assets/screens/friends/tg_prem_pt.png";
import { LANGUAGES } from "@/constants/types";
import islandSoon from "@/assets/screens/map/island_soon_open.png";
import islandSoon_en from "@/assets/screens/map/island_soon_open_en.png";
import islandSoon_fr from "@/assets/screens/map/island_soon_open_fr.png";
import islandSoon_es from "@/assets/screens/map/island_soon_open_es.png";
import islandSoon_id from "@/assets/screens/map/island_soon_open_id.png";
import islandSoon_pt from "@/assets/screens/map/island_soon_open_pt.png";
import prizeFond from "@/assets/screens/friends/prizeFond.png";
import prizeFond_en from "@/assets/screens/friends/prizeFond_en.png";

interface Translations {
  [key: string]: {
    [lang in keyof typeof LANGUAGES]: string;
  };
}

const translations: Translations = {
  " час": {
    ru: " час",
    en: "hour",
    fr: "heure",
    es: "hora",
    "es-l": "hora",
    id: "jam",
    "pt-br": "hora",
  },
  " часа": {
    ru: " часа",
    en: "hours",
    fr: "heures",
    es: "horas",
    "es-l": "horas",
    id: "jams",
    "pt-br": "horas",
  },
  " часов": {
    ru: " часов",
    en: "hours",
    fr: "heures",
    es: "horas",
    "es-l": "horas",
    id: "jams",
    "pt-br": "horas",
  },
  "1. Название и контактные данные разработчиков": {
    ru: "1. Название и контактные данные разработчиков",
    en: "1. Name and Contact Information of the Developers",
    fr: "1. Nom et coordonnées des développeurs",
    es: "1. Nombre y datos de contacto de los desarrolladores",
    "es-l": "1. Nombre y información de los Desarrolladores",
    id: "1. Nama dan Informasi Kontak Pengembang",
    "pt-br": "1. Nome e Informação de Desenvolvedores",
  },
  "1. Описание продукта": {
    ru: "1. Описание продукта",
    en: "1. Product Description",
    fr: "1. Description du produit",
    es: "1. Descripción del producto",
    "es-l": "1. Descripción del producto",
    id: "1. Deskripsi produk",
    "pt-br": "1. Descrição do Projeto",
  },
  "2. Возрастные ограничения": {
    ru: "2. Возрастные ограничения",
    en: "2. Age Restrictions",
    fr: "2. Restrictions d'âge",
    es: "2. Restricciones de edad",
    "es-l": "2. Restricciones de edad",
    id: "2. Batasan Usia",
    "pt-br": "2. Restrições de Idade",
  },
  "2. Типы собираемых данных": {
    ru: "2. Типы собираемых данных",
    en: "2. Types of Data Collected",
    fr: "2. Types de données collectées",
    es: "2. Tipos de datos recopilados",
    "es-l": "2. Tipos de datos recogidos",
    id: "2. Jenis data yang dikumpulkan",
    "pt-br": "2. Tipos de dados coletados",
  },
  "3. Цели сбора данных": {
    ru: "3. Цели сбора данных",
    en: "3. Purposes of Data Collection",
    fr: "3. Objectifs de la collecte de données",
    es: "3. Propósitos de la recopilación de datos",
    "es-l": "3. Propositos de recogida de datos",
    id: "3. Tujuan pengumpulan data",
    "pt-br": "3. Propósitos de coleta de dados",
  },
  "3. Регистрация и аккаунты": {
    ru: "3. Регистрация и аккаунты",
    en: "3. Registration and Accounts",
    fr: "3. Inscription et comptes",
    es: "3. Registro y Cuentas",
    "es-l": "3. Registro y Cuentas",
    id: "3. Pendaftaran dan Akun",
    "pt-br": "3. Registro e Contas",
  },
  "4. Передача данных третьим лицам": {
    ru: "4. Передача данных третьим лицам",
    en: "4. Data Sharing with Third Parties",
    fr: "4. Partage des données avec des tiers",
    es: "4. Compartir datos con terceros",
    "es-l": "4. Compartir datos con terceros",
    id: "4. Penggunaan data dengan pihak ketiga",
    "pt-br": "4. Compartilhamento de dados com terceiros",
  },
  "4. Использование контента": {
    ru: "4. Использование контента",
    en: "4. Use of Content",
    fr: "4. Utilisation du contenu",
    es: "4. Uso de Contenido",
    "es-l": "4. Uso de Contenido",
    id: "4. Penggunaan Konten",
    "pt-br": "4. Uso de Conteúdo",
  },
  "5. Безопасность данных": {
    ru: "5. Безопасность данных",
    en: "5. Data Security",
    fr: "5. Sécurité des données",
    es: "5. Seguridad de los datos",
    "es-l": "5. Seguridad de los datos",
    id: "5. Keamanan data",
    "pt-br": "5. Segurança de dados",
  },
  "5. Платежи и возвраты": {
    ru: "5. Платежи и возвраты",
    en: "5. Payments and Refunds",
    fr: "5. Paiements et retours",
    es: "5. Pagos y reembolsos",
    "es-l": "5. Pagos y reembolsos",
    id: "5. Pembayaran dan Pengembalian Dana",
    "pt-br": "5. Pagamentos e Reembolsos",
  },
  "6. Срок хранения данных": {
    ru: "6. Срок хранения данных",
    en: "6. Data Retention Period",
    fr: "6. Période de conservation des données",
    es: "6. Período de retenciòn de datos",
    "es-l": "6. Período de retenciòn de los datos",
    id: "6. Periode pengelolaan data",
    "pt-br": "6. Período de retenção de dados",
  },
  "6. Поведение пользователей": {
    ru: "6. Поведение пользователей",
    en: "6. User Conduct",
    fr: "6. Conduite des utilisateurs",
    es: "6. Conducta de los usuarios",
    "es-l": "6. Conducta de los usuarios",
    id: "6. Perilaku Pengguna",
    "pt-br": "6. Conduta do Usuário",
  },
  "7. Ответственность": {
    ru: "7. Ответственность",
    en: "7. Liability",
    fr: "7. Responsabilités",
    es: "7. Responsabilidad",
    "es-l": "7. Responsabilidad",
    id: "7. Pertanggungjawaban",
    "pt-br": "7. Responsabilidade",
  },
  "7. Права пользователей": {
    ru: "7. Права пользователей",
    en: "7. User Rights",
    fr: "7. Droits de l'utilisateur",
    es: "7. Derechos de usuario",
    "es-l": "7. Derechos de usuario",
    id: "7. Hak pengguna",
    "pt-br": "7. Direitos do usuário",
  },
  "8. Изменения в политике конфиденциальности": {
    ru: "8. Изменения в политике конфиденциальности",
    en: "8. Changes to the Privacy Policy",
    fr: "8. Modifications de la politique de confidentialité",
    es: "8. Cambios en la Política de privacidad",
    "es-l": "8. Cambios en la política de privacidad",
    id: "8. Perubahan pada kebijakan privasi",
    "pt-br": "8. Alterações na POLÍTICA DE PRIVACIDADE",
  },
  "8. Изменения в условиях использования": {
    ru: "8. Изменения в условиях использования",
    en: "8. Changes to the Terms of Use",
    fr: "8. Modifications de conditions d'utilisation",
    es: "8. Cambios en los Terminos de uso",
    "es-l": "8. Cambios en los Terminos de Uso",
    id: "8. Perubahan pada Ketentuan Penggunaan",
    "pt-br": "8. Alterações nos Termos de Uso",
  },
  "9. Заключительные положения": {
    ru: "9. Заключительные положения",
    en: "9. Final Provisions",
    fr: "9. Dispositions finales",
    es: "9. Disposiciones finales",
    "es-l": "9. Principios finales",
    id: "9. Ketentuan Akhir",
    "pt-br": "9. Conclusão",
  },
  "Об игре": {
    ru: "Об игре",
    en: "About the Game",
    fr: "A propos du jeu",
    es: "Sobre el Juego",
    "es-l": "Sobre el Juego",
    id: "Tentang Permainan",
    "pt-br": "Sobre o Jogo",
  },
  "Все материалы и контент, представленные в игре Pirate Empire, являются интеллектуальной собственностью разработчиков и защищены законом об авторском праве. Пользователи не имеют права копировать, распространять, изменять или использовать контент игры без письменного разрешения разработчиков.":
    {
      ru: "Все материалы и контент, представленные в игре Pirate Empire, являются интеллектуальной собственностью разработчиков и защищены законом об авторском праве. Пользователи не имеют права копировать, распространять, изменять или использовать контент игры без письменного разрешения разработчиков.",
      en: "All materials and content presented in the game Pirate Empire are the intellectual property of the developers and are protected by copyright law. Users may not copy, distribute, modify, or use the game's content without the written permission of the developers.",
      fr: "Tous les documents et le contenu présentés dans le jeu Pirate Empire sont la propriété intellectuelle des développeurs et sont protégés par la loi sur les droits d'auteur. Les utilisateurs ne peuvent pas copier, distribuer, modifier ou utiliser le contenu du jeu sans l'autorisation écrite des développeurs.",
      es: "Todos los materiales y contenidos presentados en el juego Pirate Empire son propiedad intelectual de los desarrolladores y están protegidos por la ley de derechos de autor. Los usuarios no pueden copiar, distribuir, modificar o utilizar el contenido del juego sin el permiso por escrito de los desarrolladores.",
      "es-l":
        "Todos los materiales y contenidos presentados en el juego Pirate Empire son propiedad intelectual de los desarrolladores y están protegidos por la ley de derechos de autor. Los usuarios no pueden copiar, distribuir, modificar o utilizar el contenido del juego sin el permiso por escrito de los desarrolladores.",
      id: "Semua material dan konten yang disajikan dalam Pirate Empire adalah hak milik intelektual pengembang dan dilindungi oleh undang-undang hak cipta. Pengguna tidak diizinkan untuk menyalin, mendistribusikan, memodifikasi, atau menggunakan konten game tanpa izin tertulis dari pengembang",
      "pt-br":
        "Todos os materiais e conteúdos apresentados no jogo Pirate Empire são de propriedade intelectual dos desenvolvedores e estão protegidos pela lei de direitos autorais. Os usuários não podem copiar, distribuir, modificar ou usar o conteúdo do jogo sem a permissão por escrito dos desenvolvedores.",
    },
  "За всё время заработано:": {
    ru: "За всё время заработано:",
    en: "All time earned:",
    fr: "Gagné depuis le début :",
    es: "Ganancias totales:",
    "es-l": "Ingresos totales:",
    id: "Total pendapatan:",
    "pt-br": "Total ganho:",
  },
  "ВЫ УВЕРЕНЫ, ЧТО ГОТОВЫ ПРИМЕНИТЬ БУСТЕР?": {
    ru: "ВЫ УВЕРЕНЫ, ЧТО ГОТОВЫ ПРИМЕНИТЬ БУСТЕР?",
    en: "ARE YOU SURE YOU WANT TO USE THE BOOSTER?",
    fr: "VOUS ÊTES SÛR D’ÊTRE PRÊT À UTILISER LE BOOSTER ?",
    es: "¿ESTÁS SEGURO DE QUE QUIERES UTILIZAR EL BOOSTER?",
    "es-l": "¿SEGURO QUE ESTÁS LISTO PARA UTILIZAR EL BOOSTER?",
    id: "YAKIN KAMU SIAP MENGGUNAKAN BOOSTER INI?",
    "pt-br": "É VOCE QUE DESEJA USAR O BOOSTER?",
  },
  "Аватар (если установлен)": {
    ru: "Аватар (если установлен)",
    en: "Avatar (if set)",
    fr: "Avatar (si existe)",
    es: "Avatar (si se establece)",
    "es-l": "Avatar (si establecido)",
    id: "Avatar (jika ditetapkan)",
    "pt-br": "Avatar (se estabelecido)",
  },
  Бустеры: {
    ru: "Бустеры",
    en: "Boosters",
    fr: "Boosters",
    es: "Boosters",
    "es-l": "Boosters",
    id: "Penguat",
    "pt-br": "Pacotes bonus",
  },
  БРОНЗА: {
    ru: "БРОНЗА",
    en: "BRONZE",
    fr: "BRONZE",
    es: "BRONCE",
    "es-l": "BRONCE",
    id: "PERUNGGU",
    "pt-br": "BRONZE",
  },
  Построить: {
    ru: "Построить",
    en: "Build",
    fr: "Construire",
    es: "Construir",
    "es-l": "Construir",
    id: "Bangun",
    "pt-br": "Construir",
  },
  "Постройте здание": {
    ru: "Постройте здание",
    en: "Build a building",
    fr: "Construire un bâtiment",
    es: "Construir un edificio",
    "es-l": "Construye un edificio",
    id: "Bangun sebuah bangunan",
    "pt-br": "Construa um edifício",
  },
  "Стройте и улучшайте здания, чтобы получать больше прибыли": {
    ru: "Стройте и улучшайте здания, чтобы получать больше прибыли",
    en: "Build and upgrade buildings to earn more profit",
    fr: "Construisez et améliorez des bâtiments pour avoir plus de gain",
    es: "Construye y mejora tus edificios para obtener más ganancias",
    "es-l": "Construye y mejora los edificios para obtener más beneficios",
    id: "Bangun dan upgrade bangunan untuk menghasilkan keuntungan lebih besar",
    "pt-br": "Construa e melhore os edifiços para obter mais lucros",
  },
  "ПОСТРОЙКА ЗДАНИЯ ДОБЫЧИ МОНЕТ": {
    ru: "ПОСТРОЙКА ЗДАНИЯ ДОБЫЧИ МОНЕТ",
    en: "BUILDING COIN PRODUCTION STRUCTURE",
    fr: "CONSTRUCTION DE BATIMENT DE PRODUCTION DE PIÈCES",
    es: "CONSTRUIR EDIFICIO DE EXTRACCIÓN DE MONEDAS",
    "es-l": "EDIFICIO DE PRODUCCIÓN DE MONEDAS",
    id: "MEMBANGUN BANGUNAN PRODUKSI KOIN",
    "pt-br": "CONSTRUINDO UM PRÉDIO DE PRODUÇÃO DE MOEDAS",
  },
  Здания: {
    ru: "Здания",
    en: "Buildings",
    fr: "Bâtiments",
    es: "Edificios",
    "es-l": "Edificaciones",
    id: "Bangunan",
    "pt-br": "Edifícios",
  },
  "Здания можно улучшить": {
    ru: "Здания нужно улучшать",
    en: "Buildings need to be upgraded.",
    fr: "Les bâtiments à améliorer.",
    es: "Los edificios deben mejorarse",
    "es-l": "Los edificios se pueden mejorar",
    id: "Bangunan perlu di-upgrade",
    "pt-br": "Os edíficios podem ser melhorados",
  },
  БУСТЕРЫ: {
    ru: "БУСТЕРЫ",
    en: "BOOSTERS",
    fr: "BOOSTERS",
    es: "BOOSTERS",
    "es-l": "POTENCIADORES",
    id: "PENGUAT",
    "pt-br": "PACOTES BÔNUS",
  },
  "Изменения в политике конфиденциальности": {
    ru: "Изменения в политике конфиденциальности",
    en: "Changes to the privacy policy",
    fr: "Modifications de la politique de confidentialité",
    es: "Cambios en la Política de privacidad",
    "es-l": "Cambios en la política de privacidad",
    id: "Perubahan kebijakan privasi",
    "pt-br": "Alterações na POLÍTICA DE PRIVACIDADE",
  },
  Забрать: {
    ru: "Забрать",
    en: "Claim",
    fr: "Récupérer",
    es: "Reclamar",
    "es-l": "Reclamar",
    id: "Klaim",
    "pt-br": "Reclamar",
  },
  "Нажмите на постройку для взаимодействия с ней": {
    ru: "Нажмите на постройку для взаимодействия с ней",
    en: "Click on the building to interact with it",
    fr: "Cliquez sur le bâtiment pour interagir",
    es: "Haz clic en el edificio para interactuar con él",
    "es-l": "Haz clic en el edificio para interactuar con él",
    id: "Klik bangunan untuk berinteraksi dengannya",
    "pt-br": "Clique no edifício para interagir com ele",
  },
  'Нажмите кнопку "Построить"': {
    ru: 'Нажмите кнопку "Построить"',
    en: "Click the 'Build' button",
    fr: "Cliquez sur le bouton «Construire»",
    es: 'Haz clic en el botón "Construir"',
    "es-l": "Haz clic en el botón 'Construir'",
    id: "Klik tombol 'Bangun'",
    "pt-br": "Clique no botão 'Construir'",
  },
  'Нажмите на кнопку "Улучшить", чтобы узнать сколько ресурсов нужно для улучшения здания':
    {
      ru: 'Нажмите на кнопку "УЛУЧШИТЬ", чтобы узнать сколько ресурсов необходимо для улучшения здания',
      en: "Click the 'Upgrade' button to see how many resources are needed to upgrade the building",
      fr: 'Cliquez sur le bouton "Améliorer" pour savoir combien de ressources sont nécessaires pour améliorer le bâtiment',
      es: 'Haz clic en el botón "MEJORAR" para saber cuántos recursos se necesitan para mejorar el edificio',
      "es-l":
        "Haz clic en el botón Mejorar para saber cuántos recursos se necesitan para mejorar el edificio",
      id: "Klik tombol 'Upgrade' untuk melihat berapa banyak sumber daya yang dibutuhkan untuk mengupgrade bangunan",
      "pt-br":
        "Clique no botão 'Upgrade' (Atualizar) para saber quantos recursos são necessários para atualizar o edifício",
    },
  "Заберите собранное золото!": {
    ru: "Заберите собранное золото!",
    en: "Collect the gathered gold!",
    fr: "Récupérez l'or collecté !",
    es: "¡Recoge el oro recolectado!",
    "es-l": "Recoge el oro recolectado!",
    id: "Kumpulkan emas yang sudah diperoleh!",
    "pt-br": "Coleta o ouro coletado!",
  },
  "Собранные данные используются для следующих целей:": {
    ru: "Собранные данные используются для следующих целей:",
    en: "Collected data is used for the following purposes:",
    fr: "Les données collectées sont utilisees pour les besoins suivants :",
    es: "Los datos recopilados se utilizan para los siguientes objetivos:",
    "es-l": "Los datos recopilados se utilizan para los siguientes objetivos:",
    id: "Data yang didapat digunakan untuk tujuan berikut:",
    "pt-br": "Os dados coletados são utilizados para os seguintes fins:",
  },
  "Скоро будет информация об аирдропе": {
    ru: "Скоро будет информация об аирдропе!",
    en: "Coming Soon Airdrop Information!",
    fr: "Informations sur l'airdrop seront bientôt disponibles !",
    es: "¡Próximamente habrá información del Airdrop!",
    "es-l": "¡En breve llegará un drop!",
    id: "Drop akan segera hadir!",
    "pt-br": "Em breve as informações sobre o airdrop!",
  },
  "Поздравляем, вы построили своё первое здание!": {
    ru: "Поздравляем, вы построили своё первое здание!",
    en: "Congratulations, you've built your first building!",
    fr: "Félicitations, vous avez construit votre premier bâtiment !",
    es: "¡Enhorabuena, has construido tu primer edificio!",
    "es-l": "Felicidades, has construido tu primer edificio!",
    id: "Selamat, Anda telah membangun bangunan pertama Anda!",
    "pt-br": "Parabéns, construimos o seu primeiro edifiço!",
  },
  "Привязать кошелёк": {
    ru: "Привязать кошелёк",
    en: "Connect wallet",
    fr: "Connecter le portefeille",
    es: "Conectar el monedero",
    "es-l": "Enlace a la Billetera",
    id: "Dompet Tautan",
    "pt-br": "Conectar carteira",
  },
  "ОШИБКА СОЕДИНЕНИЯ! ПОЖАЛУЙСТА ПЕРЕЗАЙДИТЕ!": {
    ru: "ОШИБКА СОЕДИНЕНИЯ! ПОЖАЛУЙСТА, ПЕРЕЗАЙДИТЕ!",
    en: "CONNECTION ERROR! PLEASE RE-LOGIN!",
    fr: "ERREUR DE CONNEXION ! VEUILLEZ VOUS RECONNECTER !",
    es: "¡ERROR DE CONEXIÓN! ¡REINICIA SESIÓN!",
    "es-l": "¡ERROR DE CONEXIÓN! ¡REINCORPÓRATE, POR FAVOR!",
    id: "KESALAHAN KONEKSI! SILAKAN BERGABUNG KEMBALI!",
    "pt-br": "ERRO DE CONEXÃO! POR FAVOR, REENTRE!",
  },
  ПОСТРОЙКА: {
    ru: "ПОСТРОЙКА",
    en: "CONSTRUCTION",
    fr: "CONSTRUCTION",
    es: "CONSTRUCCIÓN",
    "es-l": "CONSTRUCCIÓN",
    id: "PEMBANGUNAN",
    "pt-br": "CONSTRUÇÃO",
  },
  "Срок хранения данных": {
    ru: "Срок хранения данных",
    en: "Data retention period",
    fr: "Duree de conservation des données",
    es: "Período de retención de datos",
    "es-l": "Período de conservación de los datos",
    id: "Periode penyimpanan data",
    "pt-br": "Período de conservação dos dados",
  },
  "Безопасность данных": {
    ru: "Безопасность данных",
    en: "Data security",
    fr: "Sécurité des données",
    es: "Seguridad de los datos",
    "es-l": "Seguridad de los datos",
    id: "Keamanan data",
    "pt-br": "Segurança dos dados",
  },
  "Передача данных третьим лицам": {
    ru: "Передача данных третьим лицам",
    en: "Data sharing with third parties",
    fr: "Partage des données avec les tiers",
    es: "Compartir datos con terceros",
    "es-l": "Compartiendo datos con terceros",
    id: "Pengiriman data ke pihak ketiga",
    "pt-br": "Transferência de dados para terceiros",
  },
  "Развивайте базу, чтобы получить больше золота и жемчуга": {
    ru: "Развивайте базу, чтобы получить больше золота и жемчуга",
    en: "Develop your base to get more gold and pearls",
    fr: "Developpez votre base pour obtenir plus d'or et de perles",
    es: "Desarrolla la base para ganar más oro y perlas",
    "es-l": "Desarrolla la base para ganar más oro y perlas",
    id: "Kembangkan markas untuk mendapatkan lebih banyak emas dan mutiara",
    "pt-br": "Desenvolva sua base para obter mais ouro e perlas",
  },
  "Название и контактные данные разработчиков": {
    ru: "Название и контактные данные разработчиков",
    en: "Developer's Name and Contact Information",
    fr: "Nom et informations de contact des developeurs",
    es: "Nombre y datos de contacto de los desarrolladores",
    "es-l": "Nombre y información de contacto de los desarrolladores",
    id: "Namaja dan informasi kontak pengembang",
    "pt-br": "Nome e informação de contato dos desenvolvedores",
  },
  "Отображаемое имя": {
    ru: "Отображаемое имя",
    en: "Display Name",
    fr: "Nom d'affichage",
    es: "Nombre para mostrar",
    "es-l": "Nombre de visualización",
    id: "Namaam",
    "pt-br": "Nome de exibição",
  },
  ДРОП: {
    ru: "ДРОП",
    en: "DROP",
    fr: "DROP",
    es: "DROP",
    "es-l": "DROP",
    id: "DROP",
    "pt-br": "PRÊMIO",
  },
  "Зарабатывайте золото": {
    ru: "Зарабатывайте золото",
    en: "Earn gold",
    fr: "Gagnez de l'or",
    es: "Ganar oro",
    "es-l": "Ganar oro",
    id: "Dapatkan emas",
    "pt-br": "Ganhe ouro",
  },
  "Заработай больше денег!": {
    ru: "Получите больше ресурсов!",
    en: "Earn more resources!",
    fr: "Gagnez plus de ressourses !",
    es: "¡Obtén más recursos!",
    "es-l": "Ganar dinero!",
    id: "Dapatkan lebih banyak sumber daya!",
    "pt-br": "Ganhe mais recursos!",
  },
  "Отправляйся в Airdrop-приключение! 💰 Зарабатывай золото и развивай свою империю. Получи бонус за вход в игру по ссылке":
    {
      ru: "💰 В Pirate Empire каждый день раздают TON за активность в игре. Лови момент!",
      en: "Embark on an Airdrop adventure! 💰 Earn gold and expand your empire. Get a bonus by joining the game through the link",
      fr: "Partez à l'aventure de l'Airdrop ! 💰 Gagnez de l'or et développez votre empire. Obtenez un bonus en entrant dans le jeu via ce lien",
      es: "¡Embárcate en una aventura de Airdrop! 💰 Gana oro y desarrolla tu imperio. Obtén un bono al ingresar al juego a través del enlace",
      "es-l":
        "¡Embárcate en una aventura de Airdrop! 💰 Gana oro y expande tu imperio. Obtén un bono al ingresar al juego a través del enlace",
      id: "Mulailah petualangan Airdrop! 💰 Dapatkan emas dan kembangkan kerajaanmu. Dapatkan bonus dengan masuk ke dalam permainan melalui tautan",
      "pt-br":
        "Embarque em uma aventura de Airdrop! 💰 Ganhe ouro e expanda seu império. Receba um bônus ao entrar no jogo através do link",
    },
  "ОШИБКА!": {
    ru: "ОШИБКА!",
    en: "ERROR!",
    fr: "ERREUR!",
    es: "¡ERROR!",
    "es-l": "¡ERROR!",
    id: "ERROR!",
    "pt-br": "ERRO!",
  },
  "Каждые 3 часа здание полностью заполняется золотом, не забывайте забирать его":
    {
      ru: "Каждые 3 часа здание полностью забивается золотом, не забывайте забирать его",
      en: "Every 3 hours the building is filled with gold, don't forget to collect it",
      fr: "Toutes les 3 heures, le bâtiment est entièrement rempli d'or, n'oubliez pas de le ramasser",
      es: "Cada 3 horas el edificio está completamente lleno de oro, no olvides recogerlo",
      "es-l":
        "Cada 3 horas el edificio se llena completamente con oro, no olvide recogerlo",
      id: "Setiap 3 jam, bangunan ini akan terisi penuh dengan emas, jangan lupa untuk mengumpulkannya",
      "pt-br":
        "A cada 3 horas, o edifício se enche de ouro, não se esqueça de coletá-lo",
    },
  'Для начала, давайте построим здание "Порт"': {
    ru: 'Для начала, давайте построим здание "Порт"',
    en: "First, let's build the 'Harbour' building",
    fr: "Pour commencer, construisez le bâtiment 'Port'",
    es: 'Para comenzar, vamos a construir el edificio "Puerto"',
    "es-l": "Para comenzar, construir el edificio 'Puerto'",
    id: "Pertama, mari buat bangunan 'Pelabuhan'",
    "pt-br": "Primeiro, construa o edifiço 'Port'",
  },
  на: {
    ru: "на",
    en: "for",
    fr: "pour",
    es: "para",
    "es-l": "para",
    id: "untuk",
    "pt-br": "para",
  },
  Форт: {
    ru: "Форт",
    en: "Fort",
    fr: "Fort",
    es: "Fortaleza",
    "es-l": "Fuerza",
    id: "Benteng",
    "pt-br": "Fortaleza",
  },
  ДРУЗЬЯ: {
    ru: "ДРУЗЬЯ",
    en: "FRIENDS",
    fr: "AMIS",
    es: "AMIGOS",
    "es-l": "AMIGOS",
    id: "TEMAN",
    "pt-br": "AMIGOS",
  },
  Друзья: {
    ru: "Друзья",
    en: "Friends",
    fr: "Amis",
    es: "Amigos",
    "es-l": "",
    id: "Teman",
    "pt-br": "Amigos",
  },
  "от их приглашённых друзей": {
    ru: "от их приглашённых друзей",
    en: "from their invited friends",
    fr: "de leurs amis invités",
    es: "de sus amigos invitados",
    "es-l": "de sus amigos invitados",
    id: "dari teman teman yang diundang",
    "pt-br": "de seus amigos que foram convidados",
  },
  "от ваших приглашённых друзей": {
    ru: "от ваших приглашённых друзей",
    en: "from your invited friends",
    fr: "de vos amis invités",
    es: "de tus amigos invitados",
    "es-l": "de sus amigos invitados",
    id: "dari teman teman yang diundang",
    "pt-br": "de seus amigos que foram convidados",
  },
  "Справка об игре": {
    ru: "Справка об игре",
    en: "Game information",
    fr: "À propos de ce jeu",
    es: "Información del juego",
    "es-l": "Ayuda del juego",
    id: "Bantuan Game",
    "pt-br": "Sinopse do jogo",
  },
  "Подарок:": {
    ru: "Подарок:",
    en: "Gift:",
    fr: "Cadeau :",
    es: "Regalo:",
    "es-l": "Regalo:",
    id: "Hadiah:",
    "pt-br": "Presente:",
  },
  "Перейти в магазин": {
    ru: "Перейти в магазин",
    en: "Go to the shop",
    fr: "Aller au magasin",
    es: "Ir al tienda",
    "es-l": "Ir al tienda",
    id: "Pergi ke toko",
    "pt-br": "Ir para o loja",
  },
  ЗОЛОТО: {
    ru: "ЗОЛОТО",
    en: "GOLD",
    fr: "OR",
    es: "ORO",
    "es-l": "ORO",
    id: "EMAS",
    "pt-br": "OURO",
  },
  Порт: {
    ru: "Порт",
    en: "Harbour",
    fr: "Port",
    es: "Puerto",
    "es-l": "Puerto",
    id: "Pelabuhan",
    "pt-br": "Porto",
  },
  "Порт. Постройте здание": {
    ru: "Порт. Постройте здание",
    en: "Harbour. Build a building",
    fr: "Port. Construire un bâtiment",
    es: "Puerto. Construye un edificio",
    "es-l": "Puerto. Construye un edificio",
    id: "Pelabuhan. Bangun sebuah bangunan",
    "pt-br": "Port. Construa um edifício",
  },
  "Улучшение качества игрового опыта": {
    ru: "Улучшение качества игрового опыта",
    en: "Improvement of gameplay experience",
    fr: "Amélioration de l'expérience de jeu",
    es: "Mejora tu experiencia de juego",
    "es-l": "Mejora de la experiencia de juego",
    id: "Peningkatan kinerja game",
    "pt-br": "Melhoria da experiência do jogo",
  },
  "В случае возникновения споров, стороны обязуются решать их путем переговоров. Если спор не удастся урегулировать, он подлежит рассмотрению в компетентном суде.":
    {
      ru: "В случае возникновения споров, стороны обязуются решать их путем переговоров. Если спор не удастся урегулировать, он подлежит рассмотрению в компетентном суде.",
      en: "In the event of disputes, the parties agree to resolve them through negotiations. If the dispute cannot be resolved, it will be subject to review in a competent court.",
      fr: "Dans le cas de conflits, les parties s'angage à les resoudre à l'amiable. Si le conflit ne peut être résolu, il sera soumis à examen devant un tribunal compétent.",
      es: "En caso de litigio, las partes se comprometen a resolverlo mediante negociaciones. Si el litigio no puede resolverse, se someterá a la consideración de un tribunal competente.",
      "es-l":
        "En caso de litigio, las partes se comprometen a resolverlo mediante negociación. Si el litigio no pudiera resolverse, se someterá al examen del tribunal competente",
      id: "Ini jika ada permasalahan, pihak pihak harus menyelesaikannya melalui perundingan. Jika permasalahan tidak dapat diselesaikan, permasalahan akan diselenggarakan di daerah yang kompeten.",
      "pt-br":
        "Em caso de controvérsias, as partes se comprometem a resolvê-las por meio de negociação. Se a controvérsia não puder ser resolvida, ela estará sujeita à análise do tribunal competente.",
    },
  "Анализ внутриигровых действий": {
    ru: "Анализ внутриигровых действий",
    en: "In-game actions analysis",
    fr: "Analyse des actions pendant le jeu",
    es: "Analisis de acciones en el juego",
    "es-l": "Analisis de acciones en el juego",
    id: "Analisis aktifitas game",
    "pt-br": "Análise das ações dentro do jogo",
  },
  "Данные о внутриигровых действиях и прогрессе": {
    ru: "Данные о внутриигровых действиях и прогрессе",
    en: "In-game actions and progress data",
    fr: "Données d'action et de progression pendant le jeu",
    es: "Datos de acciones y progreso en el juego",
    "es-l": "Datos de acciones y progreso en el juego",
    id: "Data aktifitas dan progres di game",
    "pt-br": "Dados de ações e progresso no jogo",
  },
  "Информация о взаимодействиях пользователей с игрой": {
    ru: "Информация о взаимодействиях пользователей с игрой",
    en: "Information about user interactions with the game",
    fr: "Information sur les interactions des utilisateurs avec le jeu",
    es: "Información sobre las interacciones de los usuarios con el juego",
    "es-l": "Información sobre las interacciones de los usuarios con el juego",
    id: "Informasi tentang interaksi pengguna dengan game",
    "pt-br": "Informação sobre as interações dos usuários com o jogo",
  },
  "Пригласите 10 друзей": {
    ru: "Пригласите 10 друзей",
    en: "Invite 10 friends",
    fr: "Invitez 10 amis",
    es: "Invita a 10 amigos",
    "es-l": "Invita 10 amigos",
    id: "Undang 10 teman",
    "pt-br": "Convidar 10 amigos",
  },
  "Пригласить друга!": {
    ru: "Пригласить друга!",
    en: "Invite a friend!",
    fr: "Inviter un ami !",
    es: "¡Invita a un amigo!",
    "es-l": "Invita a un amigo!",
    id: "Undang Teman!",
    "pt-br": "Convide um amigo!",
  },
  "ПРИГЛАШАЙ ДРУЗЕЙ!": {
    ru: "ПРИГЛАШАЙ ДРУЗЕЙ!",
    en: "INVITE YOUR FRIENDS!",
    fr: "INVITEZ VOS AMIS !",
    es: "¡INVITA A TUS AMIGOS!",
    "es-l": "¡INVITA A UN AMIGO!",
    id: "UNDANG TEMAN!",
    "pt-br": "CONVIDE SEUS AMIGOS!",
  },
  "Оно уже начало зарабатывать вам золото! Каждые 3 часа вам нужно забирать заработанное  золото, иначе здание перестанет зарабатывать":
    {
      ru: "Оно уже начало приносить вам золото! Каждые 3 часа вам нужно забирать золото, иначе здание переполнится",
      en: "It has started earning you gold! Every 3 hours, you need to collect the earned gold, or the building will overflow",
      fr: "Ceci a déjà commencé à vous faire gagner de l'or ! Toutes les 3 heures, vous devez ramasser l'or que vous avez gagné, sinon le bâtiment sera trop plein",
      es: "¡Ya ha empezado a ganarte oro! Cada 3 horas, tienes que recopilar el oro ganado, o el edificio se desbordará.",
      "es-l":
        "¡Ya empezaste a ganar oro! Cada 3 horas tienes que recolectar el oro ganado, de lo contrario, el edificio dejará de ganar",
      id: "Bangunan ini sudah mulai menghasilkan emas untukmu! Setiap 3 jam kamu perlu mengumpulkan emas yang diperoleh, jika tidak, bangunan akan meluap",
      "pt-br":
        "Você já começou a ganhar ouro! A cada 3 horas, você precisa coletar o ouro ganho, ou o prédio transbordará",
    },
  "Присоединяйтесь к нашему Telegram-каналу": {
    ru: "Присоединяйтесь к нашему Telegram-каналу",
    en: "Join our Telegram channel",
    fr: "Rejoignez notre chaîne Telegram",
    es: "Únete a nuestro canal de Telegram",
    "es-l": "Unirse a nuestro canal de Telegram",
    id: "Join our Telegram channel",
    "pt-br": "Participe de nosso canal no Telegram",
  },
  Путешествие: {
    ru: "Путешествие",
    en: "Journey",
    fr: "Voyage",
    es: "Viaje",
    "es-l": "Viaje",
    id: "Penjelajahan",
    "pt-br": "Viagem",
  },
  ЯЗЫК: {
    ru: "ЯЗЫК",
    en: "LANGUAGE",
    fr: "LANGUE",
    es: "IDIOMA",
    "es-l": "IDIOMA",
    id: "BAHASA",
    "pt-br": "IDIOMA",
  },
  Рейтинг: {
    ru: "Рейтинг",
    en: "Rating",
    fr: "Classement",
    es: "Clasificación",
    "es-l": "",
    id: "Penilaian",
    "pt-br": "Classificação",
  },
  "Давайте отправим корабль в путешествие, нажмите на свиток": {
    ru: "Давайте отправим корабль в путешествие, нажмите на свиток",
    en: "Let's send the ship on a journey, click on the scroll",
    fr: "Envoyez le navire en voyage, cliquez sur le rouleau",
    es: "Enviemos el barco de viaje, haz clic en el desplazamiento",
    "es-l": "Enviemos el barco de viaje, haz clic en el desplazamiento",
    id: "Ayo kirim kapal dalam penjelajahan, klik pada gulungan",
    "pt-br": "Envie o navio para uma viagem, clique no rolagem",
  },
  Карта: {
    ru: "Карта",
    en: "Map",
    fr: "Carte",
    es: "Mapa",
    "es-l": "Mapa",
    id: "Kartu",
    "pt-br": "Mapa",
  },
  МЕНЮ: {
    ru: "МЕНЮ",
    en: "MENU",
    fr: "MENU",
    es: "MENÚ",
    "es-l": "MENÚ",
    id: "MENU",
    "pt-br": "MENU",
  },
  "Список моих друзей:": {
    ru: "Список моих друзей:",
    en: "My friends list:",
    fr: "Liste de mes amis:",
    es: "Lista de mis amigos:",
    "es-l": "Lista de mis amigos:",
    id: "Daftar teman saya:",
    "pt-br": "Lista de meus amigos:",
  },
  "Список друзей моих друзей:": {
    ru: "Список друзей моих друзей:",
    en: "My friends' friends list:",
    fr: "Liste des amis de mes amis:",
    es: "Lista de los amigos de mis amigos:",
    "es-l": "Lista de los amigos de mis amigos:",
    id: "Daftar teman dari teman saya:",
    "pt-br": "Lista de amigos dos meus amigos:",
  },
  Далее: {
    ru: "Далее",
    en: "Next",
    fr: "Suivant",
    es: "Continuar",
    "es-l": "Continuar",
    id: "Lanjut",
    "pt-br": "Continuar",
  },
  "Нужен ключ!": {
    ru: "Нужен ключ!",
    en: "Needs Key!",
    fr: "Besoin de clés !",
    es: "¡Necesita llave!",
    "es-l": "¡Sin llaves!",
    id: "Tidak ada kunci!",
    "pt-br": "Não tem Chave!",
  },
  "Не хватает ресурсов!": {
    ru: "Не хватает ресурсов!",
    en: "Not enough resources!",
    fr: "Des ressources insuffisantes !",
    es: "¡No hay suficientes recursos!",
    "es-l": "No hay suficientes recursos!",
    id: "Kurang bahan!",
    "pt-br": "Não há recursos suficientes!",
  },
  ВЫКЛ: {
    ru: "ВЫКЛ",
    en: "OFF",
    fr: "DÉSACTIVER",
    es: "APAGAR",
    "es-l": "Apagar",
    id: "Matikan",
    "pt-br": "DESLIGAR",
  },
  ВКЛ: {
    ru: "ВКЛ",
    en: "ON",
    fr: "ACTIVER",
    es: "ENCENDER",
    "es-l": "ENCENDER",
    id: "NYALAKAN",
    "pt-br": "LIGAR",
  },
  "Только самые успешные пираты пробиваются в топ рейтинга, завоевывая уважение и признание среди других морских волков. Кто знает, возможно, впереди их ждут невероятные призы и славные награды!":
    {
      ru: "Только самые успешные пираты пробиваются в топ рейтинга, завоевывая уважение и признание среди других морских волков. Кто знает, возможно, впереди их ждут невероятные призы и славные награды!",
      en: "Only the most successful pirates reach the top of the leaderboard, earning respect and recognition from other seafarers. Who knows, perhaps incredible prizes and glorious rewards await them ahead!",
      fr: "Seuls les pirates les plus performants arrivent en tête du classement, gagnant le respect et la reconnaissance des autres loups de mer. Qui sait, peut-être ils gagneront des prix incroyables et des superbes récompenses !",
      es: "Solo los piratas más exitosos consiguen llegar a lo más alto de la clasificación, ganándose el respeto y el reconocimiento de los demás marinos. ¡Quién sabe, tal vez increíbles premios y gloriosas recompensas les esperan por delante!",
      "es-l":
        "Sólo los piratas más exitosos se abren camino hasta lo más alto de la clasificación, ganándose el respeto y el reconocimiento de los demás lobos de mar. Quién sabe, ¡puede que les esperen premios increíbles y recompensas gloriosas!",
      id: "Hanya bajak laut yang paling sukses yang berhasil mencapai peringkat teratas, mendapatkan rasa hormat dan pengakuan di antara serigala laut lainnya. Siapa tahu, mungkin ada hadiah yang luar biasa dan penghargaan yang gemilang di depan mereka!",
      "pt-br":
        "Somente os piratas mais bem-sucedidos chegam ao topo da classificação, ganhando respeito e reconhecimento entre outros lobos do mar. Quem sabe, pode haver prêmios incríveis e recompensas gloriosas à sua frente!",
    },
  "ОТКРОЙ СУНДУК": {
    ru: "ОТКРОЙ СУНДУК",
    en: "OPEN CHEST",
    fr: "OUVREZ LE COFFRE",
    es: "ABRIR EL COFRE",
    "es-l": "ABRIR CESTA",
    id: "BUKA TERBUKA",
    "pt-br": "ABRIR CAIXA",
  },
  'Откройте сундук и получите бонус "множитель"!': {
    ru: 'Откройте сундук и получите бустер "Множитель"!',
    en: "Open the chest and get a 'Multiplier' booster!",
    fr: 'Ouvrez le coffre et gagnez un booster "Multiplicateur" !',
    es: '¡Abre el cofre y obtén el booster "Multiplicador"!',
    "es-l": "¡Abre el cofre y recibe el bono 'Multiplicador'!",
    id: "Buka peti dan dapatkan booster 'Pengganda'!",
    "pt-br": "Abra o baú e obtenha um booster 'Multiplicador'!",
  },
  ЖЕМЧУГ: {
    ru: "ЖЕМЧУГ",
    en: "PEARLS",
    fr: "PERLES",
    es: "PERLAS",
    "es-l": "PERLAS",
    id: "MUTIARA",
    "pt-br": "PÉROLAS",
  },
  "Номер телефона (если предоставлен пользователем)": {
    ru: "Номер телефона (если предоставлен пользователем)",
    en: "Phone Number (if provided by user)",
    fr: "Numéro de téléphone (si fourni par l'utilisateur)",
    es: "Número de teléfono (si lo ha facilitado el usuario)",
    "es-l": "Número de telefono (si proporcionado por el usuario)",
    id: "Nomor telepon (jika diberikan oleh pengguna)",
    "pt-br": "Telefone (se fornecido pelo usuário)",
  },
  "Pirate Empire — это стратегическая игра с элементами менеджмента, в которой пользователи управляют островами и развивают свои территории. Главной целью является строительство и улучшение зданий на нескольких островах, которые становятся доступны по мере прохождения игры.":
    {
      ru: "Pirate Empire — это стратегическая игра с элементами менеджмента, в которой пользователи управляют островами и развивают свои территории. Главной целью является строительство и улучшение зданий на нескольких островах, которые становятся доступны по мере прохождения игры.",
      en: "Pirate Empire is a strategy game with management elements where users manage islands and develop their territories. The main goal is to build and upgrade buildings on several islands that become available as the game progresses.",
      fr: "Pirate Empire est un jeu de stratégie avec des éléments de gestion dans lequel les utilisateurs gèrent des îles et développent leurs territoires. L'objectif principal est de construire et d'améliorer des bâtiments sur plusieurs îles, qui deviennent disponibles au fur et à mesure que vous progressez dans le jeu.",
      es: "Pirate Empire es un juego de estrategia con elementos de gestión, en el que los usuarios gestionan sus islas y desarrollan sus territorios. El objetivo principal es construir y mejorar los edificios de varias islas, que estarán disponibles a medida que avance el juego.",
      "es-l":
        "Pirate Empire es un juego de estrategia con elementos de gestión en el que los usuarios administran islas y expanden sus territorios. El objetivo principal es construir y mejorar los edificios de varias islas, que estarán disponibles a medida que los jugadores avancen en el juego",
      id: "Pirate Empire adalah game strategi dengan elemen manajemen di mana pengguna mengelola pulau dan memperluas wilayah mereka. Tujuan utamanya adalah membangun dan mengupgrade bangunan di sejumlah pulau, yang menjadi tersedia seiring semakin bertambahnya progres pemain dalam game.",
      "pt-br":
        "Pirate Empire é um jogo de estratégia com elementos de gerenciamento no qual os usuários administram ilhas e desenvolvem seus territórios. O objetivo principal é construir e melhorar os edifícios em várias ilhas, que se tornam disponíveis à medida que você avança no jogo.",
    },
  "Политика конфиденциальности": {
    ru: "Политика конфиденциальности",
    en: "Privacy Policy",
    fr: "Politique de confidentialité",
    es: "Política de privacidad",
    "es-l": "Política de privacidad",
    id: "Kebijakan Privasi",
    "pt-br": "Politica de privacidade",
  },
  "Privacy Policy": {
    ru: "Privacy Policy",
    en: "Privacy Policy",
    fr: "Politique de confidentialité",
    es: "Privacy Policy",
    "es-l": "Política de privacidad",
    id: "Kebijakan Privasi",
    "pt-br": "Política de privacidade",
  },
  "Призовой фонд": {
    ru: "Призовой фонд",
    en: "Prize Pool",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Предоставление технической поддержки": {
    ru: "Предоставление технической поддержки",
    en: "Providing technical support",
    fr: "Assistance technique",
    es: "Prestación de soporte técnico",
    "es-l": "Proveedores de soporte tecnológico",
    id: "Penyediaan dukungan teknis",
    "pt-br": "Provedor de suporte tecnológico",
  },
  "Опубликуйте историю в Telegram": {
    ru: "Опубликуйте историю в Telegram",
    en: "Post a story in Telegram",
    fr: "Publiez votre histoire sur Telegram",
    es: "Publica la historia en Telegram",
    "es-l": "",
    id: "Posting story di Telegram",
    "pt-br": "Publique uma história no Telegram",
  },
  ПОКУПКА: {
    ru: "ПОКУПКА",
    en: "PURCHASE",
    fr: "ACHAT",
    es: "COMPRA",
    "es-l": "COMPRA",
    id: "BELI",
    "pt-br": "COMPRAR",
  },
  "Цели сбора данных": {
    ru: "Цели сбора данных",
    en: "Purpose of Data Collection",
    fr: "Objectif de la collecte des données",
    es: "Propósitos de la recopilación de datos",
    "es-l": "Objetivo de recopilación de datos",
    id: "Tujuan pengumpulan data",
    "pt-br": "Objetivo da coleta de dados",
  },
  Задания: {
    ru: "Задания",
    en: "Quests",
    fr: "Quêtes",
    es: "Tareas",
    "es-l": "Tareas",
    id: "Tugas",
    "pt-br": "Tarefas",
  },
  ЗАДАЧИ: {
    ru: "ЗАДАЧИ",
    en: "QUESTS",
    fr: "QUÊTES",
    es: "TAREAS",
    "es-l": "TAREAS",
    id: "TUGAS",
    "pt-br": "OBJETIVOS",
  },
  Переподключится: {
    ru: "Переподключиться",
    en: "Reconnect",
    fr: "Reconnecter",
    es: "Reconectar",
    "es-l": "Reconectar",
    id: "Reconnect",
    "pt-br": "Reconectar",
  },
  "Регистрация в игре не требуется. Создание аккаунта происходит автоматически при первом запуске приложения.":
    {
      ru: "Регистрация в игре не требуется. Создание аккаунта происходит автоматически при первом запуске приложения.",
      en: "Registration in the game is not required. An account is created automatically upon the first launch of the application.",
      fr: "L'enregistrement n'est pas requis dans ce jeu. Un compte est créé automatiquement lors du premier lancement de l'application.",
      es: "No es obligatorio registrarse en el juego. Al iniciar la aplicación por primera vez, se crea automáticamente una cuenta.",
      "es-l":
        "La inscripción en el juego no es obligatoria. Una cuenta es creada aquí al primer inicio de la aplicación.",
      id: "Pendaftaran tidak diperlukan. Akun akan dibuat secara otomatis saat aplikasi pertama kali diluncurkan.",
      "pt-br":
        "O registro no jogo não é necessário. A criação da conta é automática quando o aplicativo é iniciado pela primeira vez.",
    },
  "Требуется:": {
    ru: "Требуется:",
    en: "Required:",
    fr: "Requis :",
    es: "Requerido:",
    "es-l": "Requerido:",
    id: "Diperlukan:",
    "pt-br": "Requerido:",
  },
  "Сбросить аккаунт": {
    ru: "Сбросить аккаунт",
    en: "Reset account",
    fr: "Réinitialiser le compte",
    es: "Restablecer cuenta",
    "es-l": "Reiniciar cuenta",
    id: "Reset akun",
    "pt-br": "Redefinir conta",
  },
  НАГРАДА: {
    ru: "НАГРАДА",
    en: "REWARD",
    fr: "RÉCOMPENSE",
    es: "RECOMPENSA",
    "es-l": "RECOMPENSA",
    id: "HADIAH",
    "pt-br": "PRESENTE",
  },
  "Награда:": {
    ru: "Награда:",
    en: "Reward:",
    fr: "Récompense :",
    es: "Recompensa:",
    "es-l": "Recompensa:",
    id: "Hadiah:",
    "pt-br": "Recompensa:",
  },
  RoadMap: {
    ru: "RoadMap",
    en: "RoadMap",
    fr: "RoadMap",
    es: "RoadMap",
    "es-l": "RoadMap",
    id: "Roadmap",
    "pt-br": "RoadMap",
  },
  "Удачного путешествия капитан!": {
    ru: "Удачного путешествия, капитан!",
    en: "Safe travels, captain!",
    fr: "Bon voyage, capitaine!",
    es: "¡Buen viaje, capitán!",
    "es-l": "¡Buen viaje, capitán!",
    id: "Selamat jalan, kapten!",
    "pt-br": "Viagem segura, capitaão!",
  },
  Салун: {
    ru: "Салун",
    en: "Saloon",
    fr: "Saloon",
    es: "Taberna",
    "es-l": "Salon",
    id: "Salon",
    "pt-br": "Salão",
  },
  "Оставьте сообщение в нашем Telegram чате": {
    ru: "Подпишитесь на наш Telegram Чат",
    en: "Send a message in our Telegram chat",
    fr: "Rejoignez notre chat Telegram",
    es: "Envía un mensaje a nuestro chat de Telegram",
    "es-l": "Envia un mensaje en nuestro chat Telegram",
    id: "Send a message in our Telegram chat",
    "pt-br": "Deixe uma mensagem em nosso bate-papo do Telegram",
  },
  "Отправка уведомлений и обновлений": {
    ru: "Отправка уведомлений и обновлений",
    en: "Sending notifications and updates",
    fr: "Envoi de notifications et mises à jour",
    es: "Enviando notificaciones y actualizaciones",
    "es-l": "Enviando notificaciones y actualizaciones",
    id: "Pengiriman pemberitahuan dan pembaruan",
    "pt-br": "Envio de notificação e atualizações",
  },
  НАСТРОЙКИ: {
    ru: "НАСТРОЙКИ",
    en: "SETTINGS",
    fr: "PARAMÈTRES",
    es: "CONFIGURACIONES",
    "es-l": "CONFIGURACIÓN",
    id: "PENGATURAN",
    "pt-br": "CONFIGURAÇÃO",
  },
  Настройки: {
    ru: "Настройки",
    en: "Settings",
    fr: "Paramètres",
    es: "Configuraciones",
    "es-l": "Ajustes",
    id: "Pengaturan",
    "pt-br": "Configurações",
  },
  МАГАЗИН: {
    ru: "МАГАЗИН",
    en: "SHOP",
    fr: "BOUTIQUE",
    es: "TIENDA",
    "es-l": "TIENDA",
    id: "TOKO",
    "pt-br": "LOJA",
  },
  Магазин: {
    ru: "Магазин",
    en: "Shop",
    fr: "Boutique",
    es: "Tienda",
    "es-l": "Tienda",
    id: "Toko",
    "pt-br": "Loja",
  },
  СЕРЕБРО: {
    ru: "СЕРЕБРО",
    en: "SILVER",
    fr: "ARGENT",
    es: "PLATA",
    "es-l": "PLATA",
    id: "PERAK",
    "pt-br": "PRATA",
  },
  Пропустить: {
    ru: "Пропустить",
    en: "Skip",
    fr: "Passer",
    es: "Saltar",
    "es-l": "Saltar",
    id: "Lewati",
    "pt-br": "Pular",
  },
  ЗВУК: {
    ru: "ЗВУК",
    en: "SOUND",
    fr: "SON",
    es: "SONIDO",
    "es-l": "Sonido",
    id: "Suara",
    "pt-br": "SOM",
  },
  "Подпишитесь на наш YouTube-канал": {
    ru: "Подпишитесь на наш YouTube-канал",
    en: "Subscribe to our YouTube channel",
    fr: "Abonnez-vous à notre chaîne YouTube",
    es: "Suscríbete a nuestro canal de YouTube",
    "es-l": "Suscribirse a nuestro canal de YouTube",
    id: "Subscribe to our YouTube channel",
    "pt-br": "Assinar o nosso canal do YouTube",
  },
  "Подпишитесь на Pirate Empire в X": {
    ru: "Подпишитесь на Pirate Empire в X",
    en: "Subscribe to Pirate Empire at X",
    fr: "Abonnez-vous à Pirate Empire sur X",
    es: "Suscríbete a Pirate Empire en X",
    "es-l": "Suscribirse a Pirate Empire en X",
    id: "Subscribe to Pirate Empire at X",
    "pt-br": "Assinar o Pirate Empire no X",
  },
  "Подпишитесь на Pirate Empire в TikTok": {
    ru: "Подпишитесь на Pirate Empire в TikTok",
    en: "Subscribe to Pirate Empire on TikTok",
    fr: "Abonnez-vous à Pirate Empire sur TikTok",
    es: "Suscríbete a Pirate Empire en TikTok",
    "es-l": "Suscribirse a Pirate Empire en TikTok",
    id: "Subscribe to Pirate Empire on TikTok",
    "pt-br": "Assinar o Pirate Empire no TikTok",
  },
  "Подпишитесь на страницу Pirate Empire в Instagram": {
    ru: "Подпишитесь на страницу Pirate Empire в Instagram",
    en: "Subscribe to Pirate Empire's Instagram page",
    fr: "Abonnez-vous à la page Instagram de Pirate Empire",
    es: "Suscríbete a la página de Instagram de Pirate Empire",
    "es-l": "Suscribirse a la página de Pirate Empire en Instagram",
    id: "Subscribe to Pirate Empire's Instagram page",
    "pt-br": "Assinar a página do Instagram do Pirate Empire",
  },
  "Terms and conditions": {
    ru: "Terms and Conditions",
    en: "Terms and Conditions",
    fr: "Conditions générales",
    es: "Términos y condiciones",
    "es-l": "Términos y condiciones",
    id: "Syarat dan Ketentuan",
    "pt-br": "Termos e condições",
  },
  "Условия использования": {
    ru: "Условия использования",
    en: "Terms of Use",
    fr: "Conditions d'utilisation",
    es: "Términos de uso",
    "es-l": "Términos de uso",
    id: "Ketentuan Penggunaan",
    "pt-br": "Termos de uso",
  },
  "Активация бустера происходит при взаимодействии с зданием!": {
    ru: "Активация бустера происходит при взаимодействии со зданием!",
    en: "The booster activates when you interact with the building!",
    fr: "L'activation du booster se produit lors de l'interaction avec le bâtiment !",
    es: "¡El booster se activa cuando interactuas con el edificio!",
    "es-l": "El booster activa cuando interactuas con el edificio!",
    id: "Aktivasi booster dilakukan ketika berinteraksi dengan bangunan!",
    "pt-br": "O booster activa quando vocé interagir com o edificio!",
  },
  "Разработчики Pirate Empire не несут ответственности за действия пользователей в игре, а также за возможные убытки, связанные с использованием контента игры. Мы не гарантируем бесперебойную работу игры и отсутствие ошибок.":
    {
      ru: "Разработчики Pirate Empire не несут ответственности за действия пользователей в игре, а также за возможные убытки, связанные с использованием контента игры. Мы не гарантируем бесперебойную работу игры и отсутствие ошибок.",
      en: "The developers of Pirate Empire are not responsible for users' actions in the game, as well as for any losses related to the use of game content. We do not guarantee uninterrupted operation of the game or the absence of errors.",
      fr: "Les développeurs de Pirate Empire ne sont pas responsables des actions des utilisateurs de ce jeu, ni des pertes éventuelles liées à l'utilisation du contenu du jeu. Nous ne garantissons pas le bon fonctionnement du jeu et l'absence d'erreurs",
      es: "Los desarrolladores de Pirate Empire no se hacen responsables de las acciones de los usuarios en el juego, así como de las posibles pérdidas asociadas al uso del contenido del juego. No garantizamos el funcionamiento ininterrumpido del juego ni la ausencia de errores.",
      "es-l":
        "Los desarrolladores de Pirate Empire no se hacen responsables de las acciones de los usuarios en el juego, así como de las posibles pérdidas asociadas al uso del contenido del juego. No garantizamos el buen funcionamiento del juego ni la ausencia de errores",
      id: "Pengembang Pirate Empire tidak bertanggung jawab atas tindakan pengguna dalam game atau atas kerugian yang terkait dengan penggunaan konten game. Kami tidak menjamin gameplay bebas dari gangguan atau kesalahan.",
      "pt-br":
        "Os desenvolvedores do Pirate Empire não são responsáveis pelas ações dos usuários no jogo, bem como por possíveis perdas associadas ao uso do conteúdo do jogo. Não garantimos o bom funcionamento do jogo e a ausência de erros.",
    },
  "Игра включает в себя платный контент, например, покупку виртуальных валют (жемчуг, бустеры, автосбор). Все транзакции в игре являются окончательными и не подлежат возврату.":
    {
      ru: "Игра включает в себя платный контент, например, покупку виртуальных валют (жемчуг, бустеры, автосбор). Все транзакции в игре являются окончательными и не подлежат возврату.",
      en: "The game includes paid content, such as the purchase of virtual currencies (pearls, boosters, auto-collection). All transactions in the game are final and non-refundable.",
      fr: "Le jeu comprend du contenu payant, tel que l'achat de monnaies virtuelles (perles, boosters, autocollectes). Toutes les transactions dans le jeu sont définitives et non remboursables.",
      es: "El juego incluye contenidos de pago, como la compra de monedas virtuales (perlas, boosters, auto-recoger). Todas las transacciones en el juego son definitivas y no reembolsables.",
      "es-l":
        "El juego incluye contenidos de pago, como la compra de monedas virtuales (perlas, potenciadores, autoshares). Todas las transacciones del juego son definitivas y no reembolsables.",
      id: "Game ini menyertakan konten berbayar, seperti pembelian mata uang virtual (mutiara, booster, pengumpulan otomatis). Semua transaksi dalam game bersifat final dan dana yang digunakan tidak dapat dikembalikan.",
      "pt-br":
        "O jogo inclui conteúdo pago, como a compra de moedas virtuais (pérolas, boosters, autoshares). Todas as transações no jogo são finais e não reembolsáveis.",
    },
  "Игра предназначена для пользователей, достигших возраста 18 лет и старше.": {
    ru: "Игра предназначена для пользователей, достигших возраста 18 лет и старше.",
    en: "The game is intended for users aged 18 and older.",
    fr: "La jeu est destiné aux utilisateurs agés de 18 ans et plus.",
    es: "El juego está destinado a usuarios mayores de 18 años.",
    "es-l": "El juego es destinado a los usuarios de 18 a mayores.",
    id: "Game ini ditujukan untuk pengguna berusia 18 tahun ke atas",
    "pt-br": "O jogo é destinado a usuários com 18 anos de idade ou mais.",
  },
  "КОРАБЛЬ УСПЕШНО ВЕРНУЛСЯ И ЗАРАБОТАЛ СЛЕДУЮЩУЮ НАГРАДУ!": {
    ru: "КОРАБЛЬ ВЕРНУЛСЯ С ЗАРАБОТАННОЙ ДЛЯ ВАС НАГРАДОЙ!",
    en: "THE SHIP HAS RETURNED WITH A REWARD EARNED FOR YOU!",
    fr: "LE NAVIRE EST REVENU AVEC UNE RÉCOMPENSE !",
    es: "¡EL BARCO HA REGRESADO CON LA RECOMPENSA QUE GANÓ PARA TI!",
    "es-l": "¡EL BARCO REGRESÓ CON ÉXITO Y OBTUVO LA SIGUIENTE RECOMPENSA!",
    id: "KAPAL BERHASIL KEMBALI DENGAN MEMBAWA HADIAH YANG DIPEROLEHNYA UNTUKMU!",
    "pt-br": "O NAVIO RETORNOU COM UMA RECOMPENSA PARA VOCÊ!",
  },
  "Произошла ошибка при обработке заказа.": {
    ru: "Произошла ошибка при обработке заказа.",
    en: "There was an error while processing the order.",
    fr: "Une erreur s'est produite lors du traitement de la commande.",
    es: "Se produjo un error al procesar el pedido.",
    "es-l": "Se produjo un error al procesar el pedido.",
    id: "Terjadi kesalahan saat memproses pesanan.",
    "pt-br": "Ocorreu um erro ao processar o pedido.",
  },
  "Данный бонус увеличивает добычу золота. Помните, что если у вас активный бонус, то новый продлит его действие, а не заменит его!":
    {
      ru: "Данный бустер увеличивает добычу золота. Помните, что если у вас активен этот бустер, то новый продлит его действие, а не заменит его!",
      en: "This booster increases gold production. Remember, if you already have an active booster, the new one will extend its duration, not replace it!",
      fr: "Ce booster augmente l’extraction d’or. N’oubliez pas que si vous avez ce booster actif, le nouveau booster prolongera sa durée sans le remplacer !",
      es: "Este booster aumenta la producción de oro. ¡Recuerda que si tienes activo este booster, el nuevo ampliará su acción, no lo sustituirá!",
      "es-l":
        "Este bono aumenta la extracción de oro. Recuerda, si tienes un bono activo, el nuevo bono prolongará su duración y no lo reemplazará.",
      id: "Booster ini meningkatkan produksi emas. Ingat, jika kamu memiliki booster yang aktif, booster baru akan memperpanjang durasinya, bukan menggantikannya!",
      "pt-br":
        "Esse booster aumenta a produção de ouro. Lembre-se de que, caso já tenha um booster ativo, o novo estenderá seu efeito, não o substituirá!",
    },
  'Это бустер "Автосбор", он автоматически собирает 2 раза добытое золото с зданий и корабля':
    {
      ru: 'Это бустер "Автосбор", он автоматически собирает 2 раза добытое золото с зданий и корабля',
      en: "This is an 'Auto-collect' booster, it automatically collects twice the amount of gold from buildings and ships",
      fr: "Il s’agit du booster Autocollecte qui collecte automatiquement et à deux reprises l'or des bâtiments et des navires",
      es: 'Este es el booster "Auto-recoger", recoge automáticamente 2 veces el oro extraído de los edificios y del barco',
      "es-l":
        "Este es el potenciador multiplicador; al utilizarlo, aumenta la extracción de oro en el multiplicador específico durante un tiempo determinado.",
      id: "Ini adalah booster Pengganda; bila digunakan, ini akan meningkatkan penambangan emas dengan pengganda yang ditentukan selama waktu tertentu.",
      "pt-br":
        "Esse é o booster “Autocollect”, que coleta automaticamente 2x o ouro saqueado de edifícios e navios",
    },
  "Этот тип устройства не поддерживается. Пожалуйста, отсканируйте QR-код на своем телефоне!":
    {
      ru: "Этот тип устройства не поддерживается. Пожалуйста, отсканируйте QR-код на своем телефоне!",
      en: "This type of device is not supported. Please scan the QR code on your phone!",
      fr: "Ce type d'appareil n'est pas supporté. Veuillez scaner le QR code avec votre téléphone !",
      es: "Este tipo de dispositivo no es compatible. ¡Escanea el código QR en tu teléfono!",
      "es-l":
        "Este tipo de dispositivo no es compatible. ¡Por favor, escanea el código QR en tu teléfono!",
      id: "Jenis perangkat ini tidak didukung. Silakan pindai kode QR di ponsel Anda!",
      "pt-br":
        "Este tipo de dispositivo não é compatível. Por favor, escaneie o código QR no seu telefone!",
    },
  "Типы собираемых данных": {
    ru: "Типы собираемых данных",
    en: "Types of Data Collected",
    fr: "Types de données collectées",
    es: "Tipos de datos recopilados",
    "es-l": "Tipos de datos recogidos",
    id: "Tipe data yang diambil",
    "pt-br": "Tipos de dados coletados",
  },
  УЛУЧШЕНИЕ: {
    ru: "УЛУЧШЕНИЕ",
    en: "UPGRADE",
    fr: "AMÉLIORER",
    es: "MEJORAR",
    "es-l": "MEJORAR",
    id: "UPGRADE",
    "pt-br": "AMIGLIORAR",
  },
  Улучшить: {
    ru: "Улучшить",
    en: "Upgrade",
    fr: "Améliorer",
    es: "Mejorar",
    "es-l": "Mejorar",
    id: "Upgrade",
    "pt-br": "Amíliorar",
  },
  "УЛУЧШЕНИЕ ЗДАНИЯ ДОБЫЧИ МОНЕТ": {
    ru: "УЛУЧШЕНИЕ ЗДАНИЯ ДОБЫЧИ МОНЕТ",
    en: "UPGRADE COIN PRODUCTION BUILDING",
    fr: "AMÉLIORATION DU BÂTIMENT DE PRODUCTION DE PIÈCES",
    es: "MEJORAR EL EDIFICIO DE PRODUCCIÓN DE MONEDAS",
    "es-l": "AMÉLIORATION DU BÂTIMENT DE PRODUCTION DE PIÈCES",
    id: "PERKAYAAN PRODUKSI PIKET",
    "pt-br": "AMIGLIAR BATALHA DE PRODUCAO DE MOEDAS",
  },
  Использовать: {
    ru: "Использовать",
    en: "Use",
    fr: "Utiliser",
    es: "Usar",
    "es-l": "Usar",
    id: "Gunakan",
    "pt-br": "Usar",
  },
  "Данные пользователей хранятся столько, сколько необходимо для достижения целей или в соответствии с законодательством.":
    {
      ru: "Данные пользователей хранятся столько, сколько необходимо для достижения целей или в соответствии с законодательством.",
      en: "User data is stored for as long as necessary to achieve the purposes or as required by law.",
      fr: "Les données des utilisateurs sont stockées tant que necessaire pour atteindre les objectifs ou conformement à la législation.",
      es: "Los datos del usuario se almacenarán durante el tiempo que sea necesario para lograr los propósitos o según lo exija la ley.",
      "es-l":
        "Los datos de los usuarios se almacenan durante un tiempo que es necesario para lograr los objetivos o conforme a la ley.",
      id: "Data pengguna disimpan selama yang diperlukan untuk mencapai tujuan atau sesuai dengan hukum.",
      "pt-br":
        "Os dados do usuário são mantidos pelo tempo necessário para cumprir as finalidades ou conforme exigido por lei.",
    },
  "Регистрация и идентификация пользователей": {
    ru: "Регистрация и идентификация пользователей",
    en: "User registration and identification",
    fr: "Enregistrement et identification des utilisateurs",
    es: "Registro e identificación de usuarios",
    "es-l": "Registro y identificación de usuarios",
    id: "Pendaftaran dan identifikasi pengguna",
    "pt-br": "Registro e identificação de usuários",
  },
  "Права пользователей": {
    ru: "Права пользователей",
    en: "User rights",
    fr: "Droits des utilisateurs",
    es: "Derechos de usuarios",
    "es-l": "Derechos de los usuarios",
    id: "Hak pengguna",
    "pt-br": "Direitos do usuário",
  },
  "Имя пользователя": {
    ru: "Имя пользователя",
    en: "Username",
    fr: "Nom d'utilisateur",
    es: "Nombre de usuario",
    "es-l": "Nombre de usuario",
    id: "Username",
    "pt-br": "Nome de usuário",
  },
  "Пользователи обязаны соблюдать правила поведения в игре, включая запрет на мошенничество, злоупотребление ошибками (багами) и оскорбительное поведение по отношению к другим игрокам. Нарушители этих правил могут быть заблокированы без предварительного предупреждения.":
    {
      ru: "Пользователи обязаны соблюдать правила поведения в игре, включая запрет на мошенничество, злоупотребление ошибками (багами) и оскорбительное поведение по отношению к другим игрокам. Нарушители этих правил могут быть заблокированы без предварительного предупреждения.",
      en: "Users are required to adhere to the rules of conduct in the game, including prohibitions on cheating, exploiting bugs, and offensive behavior towards other players. Violators of these rules may be banned without prior warning.",
      fr: "Les utilisateurs sont tenus de respecter les règles de comportement dans le jeu, y compris l'interdiction de la tricherie, de l'utilisation abusive des bugs et des comportements abusifs à l'égard des autres joueurs. Les contrevenants à ces règles peuvent être bloqués sans avertissement préalable.",
      es: "Los usuarios deben respetar las normas de comportamiento en el juego, incluida la prohibición de hacer trampas, abusar de los bugs (errores) y tener un comportamiento abusivo con otros jugadores. Los infractores de estas normas podrán ser expulsados sin previo aviso",
      "es-l":
        "Los usuarios deben respetar las normas de comportamiento en el juego, incluida la prohibición de hacer trampas, abusar de los bugs (errores) y tener un comportamiento abusivo con otros jugadores. Los infractores de estas normas podrán ser expulsados sin previo aviso",
      id: "Pengguna harus mematuhi aturan perilaku dalam game, yang melarang perilaku curang, penyalahgunaan bug, dan perilaku yang menyinggung dan menyerang terhadap pemain lain. Pelanggar dapat diblokir tanpa peringatan sebelumnya.",
      "pt-br":
        "Os usuários devem cumprir as regras de comportamento no jogo, incluindo a proibição de trapaça, abuso de bugs (bugs) e comportamento abusivo em relação a outros jogadores. Os infratores dessas regras podem ser bloqueados sem aviso prévio.",
    },
  "Пользователи имеют право на доступ, исправление, удаление и ограничение обработки данных. Связаться с нами можно по email:":
    {
      ru: "Пользователи имеют право на доступ, исправление, удаление и ограничение обработки данных. Связаться с нами можно по email:",
      en: "Users have the right to access, correct, delete, and restrict the processing of data. You can contact us via email:",
      fr: "Les utilisateurs ont le droit d'accèder à ces données, de les corriger, supprimer et de limiter leurs traitement. Vous pouvez nous contacter par email :",
      es: "Los usuarios tienen el derecho de acceder, corregir, eliminar y restringir el procesamiento de datos. Puedes contactarnos por correo electrónico:",
      "es-l":
        "Los usuarios tienen el derecho de acceder, corregir, de suprimir y de restringir el procesamiento de datos. Puedes contactarnos por correo electrónico:",
      id: "Pengguna memiliki hak untuk mengakses, memperbaiki, menghapus, dan membatasi pemrosesan data. Anda dapat menghubungi kami melalui email:",
      "pt-br":
        "Os usuários têm o direito de acessar, retificar, apagar e restringir o processamento de dados. Você pode entrar em contato conosco pelo e-mail:",
    },
  "Проголосовать за канал Pirate Empire в Telegram": {
    ru: "Проголосовать за канал Pirate Empire в Telegram",
    en: "Vote for Pirate Empire channel in Telegram",
    fr: "Votez pour le canal Pirate Empire sur Telegram",
    es: "Vota por el canal Pirate Empire en Telegram",
    "es-l": "Vota por el canal Pirate Empire en Telegram",
    id: "Vote for Pirate Empire channel in Telegram",
    "pt-br": "Vote no canal Pirate Empire no Telegram",
  },
  "Мы собираем и обрабатываем следующую информацию, предоставляемую пользователями через их аккаунты в Telegram:":
    {
      ru: "Мы собираем и обрабатываем следующую информацию, предоставляемую пользователями через их аккаунты в Telegram:",
      en: "We collect and process the following information provided by users through their Telegram accounts:",
      fr: "Nous collectons et traitons les informations suivantes fournies par les utilisateurs via leurs comptes Telegram :",
      es: "Recopilamos y procesamos la siguiente información proporcionada por los usuarios a través de sus cuentas de Telegram:",
      "es-l":
        "Recopilamos y procesamos la siguiente información proporcionada por los usuarios a traveés de sus cuentas de Telegram:",
      id: "Kami mengumpulkan dan memproses informasi berikut yang diberikan oleh pengguna melalui akun Telegram mereka:",
      "pt-br":
        "Coletamos e processamos as seguintes informações fornecidas pelos usuários por meio de suas contas do Telegram:",
    },
  "Мы оставляем за собой право вносить изменения в политику конфиденциальности. Пользователи будут уведомлены через приложение.":
    {
      ru: "Мы оставляем за собой право вносить изменения в политику конфиденциальности. Пользователи будут уведомлены через приложение.",
      en: "We reserve the right to make changes to the privacy policy. Users will be notified through the app.",
      fr: "Nous conservons le droit de modifier la politique de confidentialité. Les utilisateurs seront notifiés par l'application.",
      es: "Nos reservamos el derecho de hacer cambios en la POLITICA DE PRIVACIDAD. Los usuarios serán notificados a través de la aplicación.",
      "es-l":
        "Nos reservamos el derecho de hacer cambios en la POLITICA DE PRIVACIDAD. Los usuarios serán notificados por la aplicación.",
      id: "Kami menjamin hak untuk memperbarui kebijakan privasi. Pengguna akan diterima melalui aplikasi.",
      "pt-br":
        "Nós nos reservamos o direito de fazer alterações na política de privacidade. Os usuários serão notificados por meio do aplicativo.",
    },
  " Мы оставляем за собой право вносить изменения в настоящие условия использования в любое время. О любых изменениях пользователи будут уведомлены через приложение Pirate Empire. Рекомендуем периодически проверять обновления условий использования.":
    {
      ru: "Мы оставляем за собой право вносить изменения в настоящие условия использования в любое время. О любых изменениях пользователи будут уведомлены через приложение Pirate Empire. Рекомендуем периодически проверять обновления условий использования.",
      en: "We reserve the right to make changes to these Terms of Use at any time. Users will be notified of any changes through the Pirate Empire app. We recommend periodically checking for updates to the Terms of Use.",
      fr: "Nous nous réservons le droit de modifier ces conditions d'utilisation à tout moment. Les utilisateurs seront informés de toute modification via l'application Pirate Empire. Nous vous recommandons de vérifier régulièrement les mises à jour des conditions d'utilisation.",
      es: "Nos reservamos el derecho a realizar cambios en los presentes Términos de uso en cualquier momento. Los usuarios serán notificados de cualquier cambio a través de la aplicación Pirate Empire. Te recomendamos que compruebes periódicamente las actualizaciones de los Términos de Uso.",
      "es-l":
        "Nos reservamos el derecho a realizar cambios en estas condiciones de uso en cualquier momento. Los usuarios serán notificados de cualquier cambio a través de la app Pirate Empire. Te recomendamos que compruebes periódicamente las actualizaciones de las condiciones de uso.",
      id: "Kami berhak untuk membuat perubahan pada Ketentuan Penggunaan ini kapan saja. Pengguna akan diberi tahu tentang perubahan apa pun melalui aplikasi Pirate Empire. Kami sarankan untuk meninjau pembaruan Ketentuan Penggunaan secara berkala.",
      "pt-br":
        "Reservamo-nos o direito de fazer alterações nestes termos de uso a qualquer momento. Os usuários serão notificados de quaisquer alterações por meio do aplicativo Pirate Empire. Recomendamos que você verifique periodicamente se há atualizações nos termos de uso.",
    },
  "Мы передаем личные данные третьим лицам исключительно на основании добровольных действий пользователей.":
    {
      ru: "Мы передаем личные данные третьим лицам исключительно на основании добровольных действий пользователей.",
      en: "We share personal data with third parties only based on voluntary actions of users.",
      fr: "Nous partageons les données personnelles avec les tiers uniquement en fonction des actions volontaires des utilisateurs.",
      es: "Compartimos datos personales con terceros únicamente sobre la base de las acciones voluntarias de los usuarios.",
      "es-l":
        "Nos compartimos datos personales solo con terceros basados en las acciones voluntarias de los usuarios.",
      id: "Kami berbagi data pribadi dengan pihak ketiga hanya berdasarkan aksi pribadi yang dilakukan oleh pengguna.",
      "pt-br":
        "Nos transferimos os dados pessoais apenas para terceiros baseados em acoes voluntarias dos usuarios.",
    },
  "Мы принимаем все необходимые меры для защиты данных, используя шифрование AES-256.":
    {
      ru: "Мы принимаем все необходимые меры для защиты данных, используя шифрование AES-256.",
      en: "We take all necessary measures to protect data using AES-256 encryption.",
      fr: "Nous effectuons toutes les mesures requises pour proteger les données en utilisant l'encryptage AES-256.",
      es: "Tomamos todas las medidas indispensables para proteger los datos usando la encriptación AES-256.",
      "es-l":
        "Tomamos todas las medidas indispensables para proteger los datos usando la encriptación AES-256.",
      id: "Kami mengambil semua tindakan yang diperlukan untuk melindungi data menggunakan enkripsi AES-256.",
      "pt-br":
        "Tomamos todas as medidas necessárias para proteger os dados usando a criptografia AES-256",
    },
  ПРИВЕТСТВИЕ: {
    ru: "ПРИВЕТСТВИЕ",
    en: "WELCOME",
    fr: "ACCUEIL",
    es: "SALUDO",
    "es-l": "SALUDO",
    id: "SELSALAMAM",
    "pt-br": "BEM-VINDO",
  },
  "ДОБРО ПОЖАЛОВАТЬ В ИГРУ. ВОТ ВАШ ПОДАРОК!": {
    ru: "ДОБРО ПОЖАЛОВАТЬ В ИГРУ. ВОТ ВАШ ПОДАРОК!",
    en: "WELCOME TO THE GAME. HERE'S YOUR GIFT!",
    fr: "BIENVENUE : BIENVENUE DANS LE JEU. VOICI VOTRE CADEAU !",
    es: "BIENVENIDO AL JUEGO. ¡AQUÍ TIENES TU REGALO!",
    "es-l": "BIENVENIDO: BIENVENIDO AL JUEGO. ¡AQUÍ ESTÁ TU REGALO!",
    id: "SELAMAT DATANG: SELAMAT DATANG DI GAME INI. TERIMALAH HADIAH UNTUKMU!",
    "pt-br": "BEM-VINDO AO JOGO. AQUI ESTÁ SEU PRESENTE!",
  },
  "Добро пожаловать на ваш остров, капитан!": {
    ru: "Добро пожаловать на ваш остров, капитан!",
    en: "Welcome to your island, captain!",
    fr: "Bienvenue sur votre île, capitaine!",
    es: "¡Bienvenido a tu isla, capitán!",
    "es-l": "¡Bienvenido a tu isla, capitán!",
    id: "Selamat datang di pulaumu, kapten!",
    "pt-br": "Bem-vindo à sua ilha, capitão!",
  },
  Снять: {
    ru: "Снять",
    en: "Withdraw",
    fr: "Retrait",
    es: "Retiro",
    "es-l": "Retiro",
    id: "Tarik",
    "pt-br": "Retirar",
  },
  Мастерскую: {
    ru: "Мастерскую",
    en: "Workshop",
    fr: "Atelier",
    es: "Taller",
    "es-l": "Taller",
    id: "Toko",
    "pt-br": "Oficina",
  },
  Вы: {
    ru: "Вы",
    en: "You",
    fr: "Vous",
    es: "Tú",
    "es-l": "Usted",
    id: "Anda",
    "pt-br": "Você",
  },
  "Вы можете отправить корабль на добычу золота и жемчуга": {
    ru: "Отправляйте корабль на добычу золота и жемчуга",
    en: "Send the ship to collect gold and pearls.",
    fr: "Vous pouvez envoyer le navire collecter de l’or et des perles.",
    es: "Envía tu barco a recoger el oro y las perlas",
    "es-l": "Puedes enviar el barco a recoger el oro y las perlas",
    id: "Kirim kapal untuk mengumpulkan emas dan mutiara.",
    "pt-br": "Envie o navio para coletar ouro e pérolas.",
  },
  "ВЫ ПОЛУЧИЛИ НАГРАДУ ЗА ОТКРЫТИЕ СУНДУКА!": {
    ru: "ВЫ ПОЛУЧИЛИ НАГРАДУ ЗА ОТКРЫТИЕ СУНДУКА!",
    en: "YOU RECEIVED A REWARD FOR OPENING THE CHEST!",
    fr: "VOUS AVEZ REÇU UNE RÉCOMPENSE POUR AVOIR OUVERT LE COFFRE !",
    es: "¡HAS RECIBIDO UNA RECOMPENSA POR ABRIR EL COFRE!",
    "es-l": "¡RECIBISTE UNA RECOMPENSA POR ABRIR EL COFRE!",
    id: "KAMU MENERIMA HADIAH KARENA TELAH MEMBUKA PETI!",
    "pt-br": "VOCE RECEBEU UMA PRESENTE POR ABRIR O COFRE!",
  },
  "ВЫ УСПЕШНО ПРИОБРЕЛИ": {
    ru: "ВЫ УСПЕШНО ПРИОБРЕЛИ",
    en: "YOU SUCCESSFULLY PURCHASED",
    fr: "VOUS AVEZ ACHETÉ AVEC SUCCÈS",
    es: "HAS COMPRADO CON ÉXITO",
    "es-l": "HAS REALIZADO LA COMPRA CON ÉXITO",
    id: "KAMU SUDAH BERHASIL MEMBELI",
    "pt-br": "VOCE COMPREU CON SUCESSO",
  },
  "Ваш заказ успешно обработан!": {
    ru: "Ваш заказ успешно обработан!",
    en: "Your order has been processed successfully!",
    fr: "Votre commande a été traitée avec succès !",
    es: "¡Tu pedido ha sido procesado correctamente!",
    "es-l": "Su pedido ha sido procesado correctamente!",
    id: "Pesanan Anda telah diproses!",
    "pt-br": "Seu pedido foi processado com sucesso!",
  },
  "Ваш заказ обрабатывается": {
    ru: "Ваш заказ обрабатывается",
    en: "Your order is being processed",
    fr: "Votre commande est en cours de traitement",
    es: "Tu pedido se está procesando",
    "es-l": "Su pedido se esta procesando",
    id: "Pesanan Anda sedang diproses",
    "pt-br": "Seu pedido está sendo processado",
  },
  " АвтоСбор — это удобство, которое вы заслужили!": {
    ru: " АвтоСбор — это удобство, которое избавит от рутинных действий!",
    en: "Auto - collect is a convenience that takes the hassle out of your routine!",
    fr: "AutoCollect est une solution pratique qui vous libère de la routine !",
    es: "¡Auto-Recoger es una herramienta cómoda que te libera de las tareas rutinarias!",
    "es-l": "",
    id: "Pengumpulan - otomatis adalah kemudahan yang membantumu menghindari rutinitas yang merepotkan!",
    "pt-br": "A coleta automática é uma facilidade que alivia sua rotina!",
  },
  " друга": {
    ru: " друга",
    en: "a friend",
    fr: "ami",
    es: "un amigo",
    "es-l": "",
    id: "seorang teman",
    "pt-br": "um amigo",
  },
  "(бесплатно)": {
    ru: "(бесплатно)",
    en: "(for free)",
    fr: "(gratuit)",
    es: "(gratis)",
    "es-l": "",
    id: "(gratis)",
    "pt-br": "(grátis)",
  },
  "* После этого вернитесь в приложение и отметьте задание как выполненное": {
    ru: "* После этого вернитесь в приложение и отметьте задание как выполненное",
    en: "* Then go back to the app and mark the task as completed",
    fr: "* Revenez ensuite à l’application et marquez l’activité comme terminée",
    es: "*Después vuelve a la app y marca la tarea como completada",
    "es-l": "",
    id: "* Lalu kembali ke aplikasi dan tandai tugas sebagai selesai",
    "pt-br":
      "* Em seguida, volte ao aplicativo e marque a tarefa como concluída",
  },
  "⏳ Ваш множитель закончился!": {
    ru: "⏳ Действие вашего множителя закончилось!",
    en: "⏳ Your multiplier has expired!",
    fr: "⏳ Votre multiplicateur a expiré !",
    es: "⏳ ¡Tu multiplicador ha caducado!",
    "es-l": "",
    id: "⏳ Penggandamu telah kedaluwarsa!",
    "pt-br": "⏳ Seu multiplicador expirou!",
  },
  "⚡ Спешите, предложение исчезнет через:": {
    ru: "⚡ Спешите, предложение исчезнет через:",
    en: "⚡ Hurry up! The offer will disappear in:",
    fr: "⚡ Dépêchez-vous, l'offre disparaîtra dans :",
    es: "⚡ ¡Date prisa! La oferta desaparecerá en:",
    "es-l": "",
    id: "⚡ Cepat! Penawaran ini akan menghilang dalam:",
    "pt-br": "⚡ Rápido! A oferta acabará em breve:",
  },
  "⚡️ Спешите, предложение исчезнет через: ": {
    ru: "⚡️ Спешите, предложение исчезнет через: ",
    en: "⚡ Hurry up! The offer will disappear in:",
    fr: "⚡️ Dépêchez-vous, l'offre disparaîtra dans :",
    es: "⚡ ¡Date prisa! La oferta desaparecerá en:",
    "es-l": "",
    id: "⚡ Cepat! Penawaran ini akan menghilang dalam:",
    "pt-br": "⚡ Rápido! A oferta acabará em breve:",
  },
  "⚡️ Это ваш последний шанс! После этого предложение исчезнет навсегда.": {
    ru: "⚡️ Это ваш последний шанс! После этого предложение исчезнет навсегда.",
    en: "⚡️ This is your last chance! After that the offer will disappear forever.",
    fr: "⚡️ C'est votre dernière chance ! Après cela, l'offre disparaîtra à jamais.",
    es: "⚡️ ¡Es tu última oportunidad! Después, la oferta desaparecerá para siempre.",
    "es-l": "",
    id: "⚡️ Ini kesempatan terakhirmu! Setelah itu penawaran ini akan hilang selamanya.",
    "pt-br":
      "⚡️ Esta é a sua última chance! A oferta desaparecerá para sempre.",
  },
  "⚡ Это ваш последний шанс! После этого предложение исчезнет навсегда.": {
    ru: "⚡ Это ваш последний шанс! После этого предложение исчезнет навсегда.",
    en: "⚡️ This is your last chance! After that the offer will disappear forever.",
    fr: "⚡️ C'est votre dernière chance ! Après cela, l'offre disparaîtra à jamais.",
    es: "⚡️ ¡Es tu última oportunidad! Después, la oferta desaparecerá para siempre.",
    "es-l": "",
    id: "⚡️ Ini kesempatan terakhirmu! Setelah itu penawaran ini akan hilang selamanya.",
    "pt-br":
      "⚡️ Esta é a sua última chance! A oferta desaparecerá para sempre.",
  },
  "🎁 Подарок для вас!": {
    ru: "🎁 Подарок для вас!",
    en: "🎁 A gift for you!",
    fr: "Un cadeau pour vous !",
    es: "🎁 ¡Un regalo para ti!",
    "es-l": "",
    id: "🎁 Hadiah untukmu!",
    "pt-br": "🎁 Um presente para você!",
  },
  "🎁 Только сейчас:": {
    ru: "🎁 Только сейчас:",
    en: "🎁 Only now:",
    fr: "Seulement maintenant :",
    es: "🎁 Solo ahora",
    "es-l": "",
    id: "🎁 Hanya sekarang:",
    "pt-br": "🎁 Somente agora:",
  },
  "🎁 Только сейчас: 2 ключа всего за $0.49 + 300 жемчуга в подарок! 🔑 Ключ открывает сундук, из которого вы получите множитель x2-x5 для сбора золота и жемчуга.":
    {
      ru: "🎁 Только сейчас: 2 ключа всего за $0.49 + 300 жемчуга в подарок! 🔑 Ключ открывает сундук, из которого вы получите множитель x2-x5 для сбора золота и жемчуга.",
      en: "🎁 Only now: 2 keys for $0.49 only + 300 pearls for free! 🔑 The key opens a chest where you will get a x2-x5 multiplier to collect gold and pearls.",
      fr: "Uniquement en ce moment : 2 clés pour seulement 0,49$ et 300 perles en cadeau ! La clé ouvre le coffre qui vous offre un multiplicateur x2-x5 pour collecter de l'or et des perles.",
      es: "🎁 Solo ahora: ¡2 llaves por solo 0,49 $ + 300 perlas gratis! 🔑 La llave abre un cofre donde obtendrás un multiplicador x2-x5 para recolectar el oro y las perlas.",
      "es-l": "",
      id: "🎁 Hanya sekarang: 2 kunci seharga $0,49 saja + 300 mutiara gratis! 🔑 Kunci ini membuka peti yang memberikanmu pengganda x2-x5 untuk mengumpulkan emas dan mutiara.",
      "pt-br":
        "🎁 Somente agora: 2 chaves por apenas US$ 0,49 + 300 pérolas grátis! 🔑 A chave abre um baú onde você receberá um multiplicador de x2-x5 para coletar ouro e pérolas.",
    },
  "🏝 Ваши здания переполнены золотом!": {
    ru: "🏝 Ваши здания переполнены золотом!",
    en: "🏝 Your buildings are overflowing with gold!",
    fr: "Vos bâtiments débordent d'or !",
    es: "🏝 ¡Tus edificios están repletos de oro!",
    "es-l": "",
    id: "🏝 Bangunanmu telah dipenuhi emas!",
    "pt-br": "🏝 Seus edifícios estão transbordando de tanto ouro!",
  },
  "🏝️ Ваши здания переполнены золотом!": {
    ru: "🏝️ Ваши здания переполнены золотом!",
    en: "🏝️ Your buildings are overflowing with gold!",
    fr: "️ Vos bâtiments débordent d'or !",
    es: "🏝️ ¡Tus edificios están repletos de oro!",
    "es-l": "",
    id: "🏝️ Bangunanmu telah dipenuhi emas!",
    "pt-br": "🏝️ Seus edifícios estão transbordando de tanto ouro!",
  },
  "💎 Невероятно! Вы выбили максимальный множитель х5!": {
    ru: "💎 Невероятно! Вы выбили максимальный множитель х5!",
    en: "💎 Unbelievable! You knocked out the maximum x5 multiplier!",
    fr: "Incroyable ! Vous avez prix le multiplicateur max x5 !",
    es: "💎 ¡Increíble! ¡Has alcanzado el multiplicador máximo x5!",
    "es-l": "",
    id: "💎 Luar biasa! Kamu mendapatkan pengganda maksimum x5!",
    "pt-br": "💎 Inacreditável! Você atingiu o multiplicador máximo de x5!",
  },
  "🔑 Ключ открывает сундук, из которого вы получите множитель x2-x5 для сбора золота и жемчуга.":
    {
      ru: "🔑 Ключ открывает сундук, из которого вы получите множитель x2-x5 для сбора золота и жемчуга.",
      en: "🔑 The key opens a chest where you will get a x2-x5 multiplier to collect gold and pearls.",
      fr: "La clé ouvre le coffre qui vous offre un multiplicateur x2-x5 pour collecter de l'or et des perles.",
      es: "🔑 La llave abre un cofre donde conseguirás un multiplicador x2-x5 para recolectar el oro y las perlas.",
      "es-l": "",
      id: "🔑 Kunci ini membuka peti yang memberikanmu pengganda x2-x5 untuk mengumpulkan emas dan mutiara.",
      "pt-br":
        "🔑 A chave abre um baú onde você receberá um multiplicador de x2-x5 para coletar ouro e pérolas.",
    },
  "🔥 Осталось:": {
    ru: "🔥 Осталось:",
    en: "🔥Time is left:",
    fr: "Reste :",
    es: "🔥 Quedan:",
    "es-l": "",
    id: "🔥Waktu tersisa:",
    "pt-br": "🔥Resta pouco tempo:",
  },
  "🔥 Последний шанс!": {
    ru: "🔥 Последний шанс!",
    en: "🔥 Last chance!",
    fr: "Dernière chance !",
    es: "🔥 ¡Última oportunidad!",
    "es-l": "",
    id: "🔥 Kesempatan terakhir!",
    "pt-br": "🔥 Última chance!",
  },
  "🕒 Успейте купить": {
    ru: "🕒 Успейте купить",
    en: "🕒 Hurry to buy",
    fr: "Dépêchez-vous d'acheter",
    es: "🕒 Apresúrate a comprar",
    "es-l": "",
    id: "🕒 Cepat beli",
    "pt-br": "🕒 Corra para comprar",
  },
  "1 КЛЮЧ": {
    ru: "1 КЛЮЧ",
    en: "1 KEY",
    fr: "1 CLÉ",
    es: "1 LLAVE",
    "es-l": "",
    id: "1 KUNCI",
    "pt-br": "1 CHAVE",
  },
  "10 БУСТЕРОВ + КЛЮЧ": {
    ru: "10 бустеров + ключ",
    en: "10 boosters + a key",
    fr: "10 boosters + clés",
    es: "10 boosters + una llave",
    "es-l": "",
    id: "10 booster + satu kunci",
    "pt-br": "10 boosters + uma chave",
  },
  "1250 ЖЕМЧУГА": {
    ru: "1250 ЖЕМЧУГА",
    en: "1250 PEARLS",
    fr: "1 250 PERLES",
    es: "1250 PERLAS",
    "es-l": "",
    id: "1250 MUTIARA",
    "pt-br": "1250 PÉROLAS",
  },
  "135 БУСТЕРОВ АвтоСбора": {
    ru: "135 БУСТЕРОВ АвтоСбора",
    en: "135 Auto - collection BOOSTERS",
    fr: "135 BOOSTERS de AutoCollect",
    es: "135 BOOSTERS de Auto-Recoger",
    "es-l": "",
    id: "135 BOOSTER Pengumpulan - otomatis",
    "pt-br": "135 Auto - BOOSTERS de coleta",
  },
  "135 КЛЮЧЕЙ": {
    ru: "135 КЛЮЧЕЙ",
    en: "135 KEYS",
    fr: "135 CLÉS",
    es: "135 LLAVES",
    "es-l": "",
    id: "135 KUNCI",
    "pt-br": "135 CHAVES",
  },
  "14 КЛЮЧЕЙ": {
    ru: "14 КЛЮЧЕЙ",
    en: "14 KEYS",
    fr: "14 CLÉS",
    es: "14 LLAVES",
    "es-l": "",
    id: "14 KUNCI",
    "pt-br": "14 CHAVES",
  },
  "17420 ЖЕМЧУГА": {
    ru: "17420 ЖЕМЧУГА",
    en: "17420 PEARLS",
    fr: "17420 PERLES",
    es: "17420 PERLAS",
    "es-l": "",
    id: "17420 MUTIARA",
    "pt-br": "17420 PÉROLAS",
  },
  "2 ключа + 300 жемчуга по цене $0.49!": {
    ru: "2 ключа + 300 жемчуга по цене $0.49!",
    en: "2 keys + 300 pearls for $0.49!",
    fr: "2 clés + 300 perles pour 0,49$ !",
    es: "2 llaves + 300 perlas a un precio de 0,49 $!",
    "es-l": "",
    id: "2 kunci + 300 mutiara seharga $0,49!",
    "pt-br": "2 chaves + 300 pérolas por US$ 0,49!",
  },
  "2 ключа всего за $0.49 + 300 жемчуга в подарок!": {
    ru: "2 ключа всего за $0.49 + 300 жемчуга в подарок!",
    en: "2 keys for $0.49 only + 300 pearls as a gift!",
    fr: "2 clés pour seulement 0,49$ et 300 perles en cadeau !",
    es: "¡2 llaves por solo 0,49 $ + 300 perlas como regalo!",
    "es-l": "",
    id: "2 kunci seharga $0,49 saja + 300 mutiara sebagai hadiah!",
    "pt-br": "2 chaves por apenas US$ 0,49 + 300 pérolas de presente!",
  },
  "25 БУСТЕРОВ АвтоСбора": {
    ru: "25 БУСТЕРОВ АвтоСбора",
    en: "25 Auto - collection BOOSTERS",
    fr: "25 BOOSTERS de AutoCollect",
    es: "25 BOOSTERS de Auto-Recoger",
    "es-l": "",
    id: "25 BOOSTER Pengumpulan - otomatis",
    "pt-br": "25 BOOSTERS de coleta automática",
  },
  "250 ЖЕМЧУГА": {
    ru: "250 ЖЕМЧУГА",
    en: "250 PEARLS",
    fr: "250 PERLES",
    es: "250 PERLAS",
    "es-l": "",
    id: "250 MUTIARA",
    "pt-br": "250 PÉROLAS",
  },
  "30 Жемчуга": {
    ru: "30 Жемчуга",
    en: "30 Pearls",
    fr: "30 perles",
    es: "30 Perlas",
    "es-l": "",
    id: "30 Mutiara",
    "pt-br": "30 Pérolas",
  },
  "32825 ЖЕМЧУГА": {
    ru: "32825 ЖЕМЧУГА",
    en: "32825 PEARLS",
    fr: "32825 PERLES",
    es: "32825 PERLAS",
    "es-l": "",
    id: "32825 MUTIARA",
    "pt-br": "32825 PÉROLAS",
  },
  "3406 ЖЕМЧУГА": {
    ru: "3406 ЖЕМЧУГА",
    en: "3406 PEARLS",
    fr: "3406 PERLES",
    es: "3406 PERLAS",
    "es-l": "",
    id: "3406 MUTIARA",
    "pt-br": "3406 PÉROLAS",
  },
  "345 БУСТЕРОВ АвтоСбора": {
    ru: "345 БУСТЕРОВ АвтоСбора",
    en: "345 Auto - collect BOOSTERS",
    fr: "345 BOOSTERS de AutoCollect",
    es: "345 BOOSTERS de Auto-Recoger",
    "es-l": "",
    id: "345 BOOSTER Pengumpulan - otomatis",
    "pt-br": "345 BOOSTERS de coleta automática",
  },
  "5 БУСТЕРОВ АвтоСбора": {
    ru: "5 БУСТЕРОВ АвтоСбора",
    en: "5 Auto - collect BOOSTERS",
    fr: "5 BOOSTERS de AutoCollect",
    es: "5 BOOSTERS de Auto-Recoger",
    "es-l": "",
    id: "5 BOOSTER Pengumpulan - otomatis",
    "pt-br": "5 BOOSTERS de coleta automática",
  },
  "5 КЛЮЧЕЙ": {
    ru: "5 КЛЮЧЕЙ",
    en: "5 KEYS",
    fr: "5 CLÉS",
    es: "5 LLAVES",
    "es-l": "",
    id: "5 KUNCI",
    "pt-br": "5 CHAVES",
  },
  "50 Жемчуга": {
    ru: "50 Жемчуга",
    en: "50 Pearls",
    fr: "50 perles",
    es: "50 Perlas",
    "es-l": "",
    id: "50 Mutiara",
    "pt-br": "50 Pérolas",
  },
  "650 БУСТЕРОВ АвтоСбора": {
    ru: "650 БУСТЕРОВ АвтоСбора",
    en: "650 Auto - collect BOOSTERS",
    fr: "650 BOOSTERS de AutoCollect",
    es: "650 BOOSTERS de Auto-Recoger",
    "es-l": "",
    id: "650 BOOSTER Pengumpulan - otomatis",
    "pt-br": "650 BOOSTERS de coleta automática",
  },
  "68 БУСТЕРОВ АвтоСбора": {
    ru: "68 БУСТЕРОВ АвтоСбора",
    en: "68 Auto - collect BOOSTERS",
    fr: "68 BOOSTERS de AutoCollect",
    es: "68 BOOSTERS de Auto-Recoger",
    "es-l": "",
    id: "68 BOOSTER Pengumpulan - otomatis",
    "pt-br": "68 BOOSTERS de coleta automática",
  },
  "6816 ЖЕМЧУГА": {
    ru: "6816 ЖЕМЧУГА",
    en: "6816 PEARLS",
    fr: "6816 PERLES",
    es: "6816 PERLAS",
    "es-l": "",
    id: "6816 MUTIARA",
    "pt-br": "6816 PÉROLAS",
  },
  "70 КЛЮЧЕЙ": {
    ru: "70 КЛЮЧЕЙ",
    en: "70 KEYS",
    fr: "70 CLÉS",
    es: "70 LLAVES",
    "es-l": "",
    id: "70 KUNCI",
    "pt-br": "70 CHAVES",
  },
  "Автосбор —": {
    ru: "Автосбор —",
    en: "Auto - collect —",
    fr: "Autocollect —",
    es: "Auto-Recoger —",
    "es-l": "",
    id: "Pengumpulan - otomatis —",
    "pt-br": "Coleta automática",
  },
  "Автосбор — это инструмент, который экономит ваше время. Активировав его, вы можете быть уверены, что не пропустите момент, когда ваши здания заполнились ресурсами, даже если не успели зайти в игру вовремя.":
    {
      ru: "Автосбор — это инструмент, который экономит ваше время. Активировав его, вы можете быть уверены, что не пропустите момент, когда ваши здания заполнились ресурсами, даже если не успели зайти в игру вовремя.",
      en: "Auto - collect is a tool that saves you time. By activating it, you can be sure that you won't miss the moment when your buildings are filled with resources, even if you didn't get into the game in time.",
      fr: "L'Autocollect est un outil qui vous fait gagner du temps. En l'activant, vous pouvez être sûr de ne pas manquer le moment où vos bâtiments sont remplis de ressources, même si vous avez manqué le début du jeu.",
      es: "Auto-Recoger — una herramienta que te ahorra tiempo. Activándola, puedes asegurarte de no perderte el momento en que tus edificios se llenen de recursos, aunque no hayas iniciado sesión en el juego a tiempo.",
      "es-l": "",
      id: "Pengumpulan - otomatis adalah alat yang menghemat waktumu. Dengan mengaktifkannya, kamu takkan melewatkan saat bangunanmu dipenuhi dengan sumber daya, bahkan jika kamu tidak masuk ke dalam game pada waktunya.",
      "pt-br":
        "A coleta automática é uma ferramenta que poupa tempo. Ao ativá-la, você garante que não perderá o exato momento em que seus edifícios estiverem cheios de recursos, mesmo que não esteja jogando.",
    },
  "Автосбор автоматически соберёт ресурсы и отправит корабль за новыми!": {
    ru: "Автосбор автоматически соберёт ресурсы и отправит корабль за новыми!",
    en: "Auto - collect will automatically grab resources and send the ship out for new ones!",
    fr: "L'Autocollecte collectera automatiquement les ressources et enverra le navire pour en trouver de nouvelles !",
    es: "Auto-Recoger recogerá automáticamente los recursos y enviará el barco por otros nuevos!",
    "es-l": "",
    id: "Pengumpulan - otomatis akan mengumpulkan sumber daya secara otomatis dan mengirim kapal untuk mencari sumber daya baru!",
    "pt-br":
      "A coleta automática coletará recursos automaticamente e enviará o navio para buscar novos recursos!",
  },
  Активировать: {
    ru: "Активировать",
    en: "Activate",
    fr: "Activer",
    es: "Activar",
    "es-l": "",
    id: "Aktifkan",
    "pt-br": "Ativar",
  },
  "В магазин": {
    ru: "В магазин",
    en: "To the store",
    fr: "Aller au magasin",
    es: "A la tienda",
    "es-l": "",
    id: "Kunjungi toko",
    "pt-br": "Acesso à loja",
  },
  "В Pirate Empire вам предстоит зарабатывать золото и строить свою пиратскую империю! Стройте здания, отправляйте корабли за золотом и расширяйте влияние. Помните, чем больше активности и золота, тем успешнее пройдет будущий Airdrop — ваш шанс получить ценные награды!":
    {
      ru: "В Pirate Empire вам предстоит зарабатывать золото и строить свою пиратскую империю! Стройте здания, отправляйте корабль за золотом и жемчугом и расширяйте влияние!",
      en: "In Pirate Empire, you must earn gold and build your pirate empire! Construct buildings, send your ship for gold and pearls and expand your influence beyond!",
      fr: "Dans Pirate Empire, vous devez gagner de l'or et construire votre empire pirate ! Construisez des bâtiments, envoyez votre navire à la recherche de l'or et des perles et étendez votre influence !",
      es: "¡En Pirate Empire, debes ganar oro y construir tu imperio pirata! ¡Construye edificios, envía tu barco en busca de oro y perlas y expande tu influencia!",
      "es-l": "",
      id: "Dalam Pirate Empire, kamu harus mendapatkan emas dan membangun kerajaan bajak lautmu! Dirikan bangunan, kirim kapalmu untuk mencari emas dan mutiara dan perluas pengaruhmu sejauh mungkin!",
      "pt-br":
        "No Pirate Empire, sua missão é acumular ouro e construir seu império pirata! Construa edifícios, envie seu navio em busca de ouro e pérolas e expanda seus domínios!",
    },
  "Весь заработанный жемчуг и ключи будут отображаться на странице друзей. Чтобы перевести их на основной баланс, необходимо нажать кнопку для снятия.":
    {
      ru: 'Количество приглашенных друзей и полученных ключей будет отображаться на странице друзей. Чтобы перевести ключи на основной баланс, достаточно нажать кнопку "Снять".',
      en: 'The number of invited friends and the keys received will be displayed on the friends\' page. To transfer the keys to the main balance, simply click the "Withdraw" button.',
      fr: "Toutes les perles et clés gagnées seront affichées sur la page de vos amis. Pour les transférer vers le solde principal, vous devez cliquer sur le bouton pour retirer.",
      es: "Todas las perlas y llaves ganadas se mostrarán en la página de tus amigos. Para transferirlos al saldo principal, tienes que pulsar el botón para retirarlos.",
      "es-l": "",
      id: "Semua mutiara dan kunci yang didapatkan akan ditampilkan di halaman temanmu. Jika kamu ingin memindahkannya ke saldo utamamu, kamu harus mengeklik tombol untuk menariknya.",
      "pt-br":
        "Todas as pérolas e chaves ganhas aparecerão na página dos seus amigos. Caso queira transferi-las para seu saldo principal, clique no botão para fazer a retirada.",
    },
  "Вы получили:": {
    ru: "Вы получили:",
    en: "You got",
    fr: "Vous avez reçu :",
    es: "Has conseguido:",
    "es-l": "",
    id: "Kamu mendapatkan",
    "pt-br": "Você conseguiu",
  },
  "для вас и вашего": {
    ru: "для вас и вашего",
    en: "for you and your",
    fr: "pour vous et votre",
    es: "para ti y tu",
    "es-l": "",
    id: "untukmu dan",
    "pt-br": "para você e seu",
  },
  "Для зданий автосбор работает в течении 6 часов, а для порта в течение 2 часов. Но если вы активируете автосбор на 4 зданиях и порту, сбор ресурсов будет синхронизирован, и вы сможете не переживать за сбор ресурсов в течение целых 9 часов.":
    {
      ru: "Для зданий автосбор работает в течении 6 часов, а для порта в течение 2 часов. Но если вы активируете автосбор на 4 зданиях и порту, сбор ресурсов будет синхронизирован, и вы сможете не переживать за сбор ресурсов в течение целых 6 часов.",
      en: "For buildings, auto - collect works for 6 hours, and for the port, it works for 2 hours. However, if you enable auto  - collect on 4 buildings and a port, the resource collection will be synchronized and you will not have to worry about it for 6 hours.",
      fr: "Pour les bâtiments, l'Autocollecte fonctionne pendant 6 heures, et pour le port pendant 2 heures. Mais si vous activez l'Autocollecte sur 4 bâtiments et le port, la collecte des ressources sera synchronisée et vous n'aurez pas à vous soucier de collecter des ressources pendant 6 heures.",
      es: "Para los edificios, el auto-recoger funciona durante 6 horas y para el puerto durante 2 horas. Pero si activas el auto-recoger en 4 edificios y un puerto, la recogida de recursos se sincronizará y podrás despreocuparte de la recogida de recursos durante 6 horas completas.",
      "es-l": "",
      id: "Untuk bangunan, pengumpulan - otomatis bekerja selama 6 jam, dan untuk pelabuhan, bekerja selama 2 jam. Namun, jika kamu mengaktifkan pengumpulan - otomatis pada 4 bangunan dan satu pelabuhan, pengumpulan sumber daya akan disinkronkan dan kamu tidak perlu mengkhawatirkannya selama 9 jam.",
      "pt-br":
        "A coleta automática é válida por 6 horas para os edifícios e por 2 horas para a porta. No entanto, caso habilite a coleta automática em 4 edifícios e uma porta, a coleta dos recursos será sincronizada e você não precisará se preocupar com a coleta por 9 horas.",
    },
  "Для порта автосбор работает в течение 3 часов. Но если вы активируете автосбор на 4 зданиях и порту, сбор ресурсов будет синхронизирован, и вы сможете не переживать за сбор ресурсов в течение целых 9 часов.":
    {
      ru: "Для зданий автосбор работает в течении 6 часов, а для порта в течение 2 часов. Но если вы активируете автосбор на 4 зданиях и порту, сбор ресурсов будет синхронизирован, и вы сможете не переживать за сбор ресурсов в течение целых 6 часов.",
      en: "For buildings, auto - collect works for 6 hours, and for the port, it works for 2 hours. However, if you enable auto  - collect on 4 buildings and a port, the resource collection will be synchronized and you will not have to worry about it for 6 hours.",
      fr: "Pour les bâtiments, l'Autocollecte fonctionne pendant 6 heures, et pour le port pendant 2 heures. Mais si vous activez l'Autocollecte sur 4 bâtiments et le port, la collecte des ressources sera synchronisée et vous n'aurez pas à vous soucier de collecter des ressources pendant 6 heures.",
      es: "Para los edificios, el auto-recoger funciona durante 6 horas y para el puerto durante 2 horas. Pero si activas el auto-recoger en 4 edificios y un puerto, la recogida de recursos se sincronizará y podrás despreocuparte de la recogida de recursos durante 6 horas completas.",
      "es-l": "",
      id: "Untuk bangunan, pengumpulan - otomatis bekerja selama 6 jam, dan untuk pelabuhan, bekerja selama 2 jam. Namun, jika kamu mengaktifkan pengumpulan - otomatis pada 4 bangunan dan satu pelabuhan, pengumpulan sumber daya akan disinkronkan dan kamu tidak perlu mengkhawatirkannya selama 9 jam.",
      "pt-br":
        "A coleta automática é válida por 6 horas para os edifícios e por 2 horas para a porta. No entanto, caso habilite a coleta automática em 4 edifícios e uma porta, a coleta dos recursos será sincronizada e você não precisará se preocupar com a coleta por 9 horas.",
    },
  "Дорогой игрок, вы были заблокированы за многократное нарушение правил": {
    ru: "Уважаемый игрок, вы были заблокированы за многократное нарушение правил",
    en: "Dear player, you have been banned for multiple rule violations",
    fr: "Cher joueur, vous avez été bloqué pour des violations répétées des règles",
    es: "Estimado jugador, has sido bloqueado por múltiples infracciones de las reglas",
    "es-l": "",
    id: "Pemain yang terhormat, Anda telah diblokir karena melakukan beberapa pelanggaran peraturan",
    "pt-br": "Estimado jogador, você foi banido por ter violado várias regras",
  },
  "Если вы опубликовали историю без удаления ссылок, вы можете подтвердить выполнение задания, и вам начислится награда. Через 24 часа можно повторить задание!":
    {
      ru: "Если вы опубликовали историю без удаления ссылок, вы можете подтвердить выполнение задания, и вам начислится награда. Через 24 часа можно повторить задание!",
      en: "If you have posted a story without removing links, you can confirm the assignment and you will be rewarded. You can do it again in 24 hours!",
      fr: "Si vous avez publié l'histoire sans supprimer les liens, vous pouvez confirmer d'avoir accompli la tâche et vous recevrez une récompense. Après 24 heures, vous pouvez répéter la tâche !",
      es: "Si has publicado una historia sin eliminar enlaces, puedes confirmar que se ha completado la tarea y se te acreditará la recompensa. ¡Podrás repetir la tarea dentro de 24 horas!",
      "es-l": "",
      id: "Jika kamu telah memposting story tanpa menghapus tautannya, kamu bisa mengonfirmasi tugasnya dan kamu akan diberikan hadiah. Kamu bisa melakukannya lagi dalam 24 jam!",
      "pt-br":
        "Ao publicar uma história sem remover os links, confirme a tarefa e você será recompensado. É possível repetir a tarefa após 24 horas!",
    },
  "Есть функция синхронного сбора, которая позволяет не собирать ресурсы вручную в течение 9 часов. Синхронный сбор автоматически выполнит эту задачу за вас!":
    {
      ru: "Есть функция синхронного сбора, которая позволяет не собирать ресурсы вручную в течение 6 часов. Синхронный сбор автоматически выполнит эту задачу за вас!",
      en: "There is a synchronized collection feature that allows you to avoid manually collecting resources for 9 hours. This option will do this task for you automatically!",
      fr: "Il existe une fonctionnalité de collecte synchrone qui vous dispense de la collecte manuelle des ressources pendant 6 heures. La collecte synchrone effectuera automatiquement cette tâche pour vous !",
      es: "Existe una función de recogida sincronizada que te permite no tener que recoger manualmente los recursos durante 6 horas. ¡La recogida sincronizada hará automáticamente esta tarea por ti!",
      "es-l": "",
      id: "Tersedia fitur pengumpulan tersinkronisasi yang membuatmu tidak perlu melakukan pengumpulan sumber daya secara manual selama 9 jam. Pilihan ini akan melakukan tugas ini untukmu secara otomatis!",
      "pt-br":
        "Existe um recurso de coleta sincronizada que permite que você evite coletar recursos manualmente no período de 9 horas. É uma opção que executará essa tarefa para você automaticamente!",
    },
  "Жемчуг.": {
    ru: "Жемчуг.",
    en: "Pearls",
    fr: "Perle.",
    es: "Perlas.",
    "es-l": "",
    id: "Mutiara",
    "pt-br": "Pérolas",
  },
  "За каждого 10-го друга вы будете награждены ключом для сундука с множителем, который увеличит добычу ресурсов в 2-5 раз!":
    {
      ru: "За каждого 10-го приглашенного друга вы получите 5 автосборов, за каждого 50-го — 10 автосборов, а за каждого 100-го — 15 автосборов. Автосборы позволяют автоматически собирать ресурсы, обеспечивая, что вы не упустите момент, когда ваши здания заполнились ресурсами, даже если не успели зайти в игру вовремя.",
      en: "For every 10th invited friend, you will receive 5 auto-collections; for every 50th, 10 auto-collections; and for every 100th, 15 auto-collections. Auto-collections allow you to automatically gather resources, ensuring you don't miss the moment when your buildings are full of resources, even if you couldn't log in to the game on time.",
      fr: "Pour chaque 10-ème ami, vous serez récompensé par une clé pour un coffre avec un multiplicateur, ce qui augmentera l'extraction de ressources de 2 à 5 fois !",
      es: "¡Por cada 10 amigos, serás recompensado con una llave para el cofre con multiplicador que aumentará de 2 a 5 veces la extracción de recursos!",
      "es-l": "",
      id: "Untuk setiap teman ke-10, kamu akan diberi hadiah kunci peti pengganda yang akan meningkatkan penambangan sumber daya sebanyak 2-5 kali lipat!",
      "pt-br":
        "A cada grupo de 10 amigos, você será recompensado com uma chave de baú multiplicadora que aumentará a coleta de recursos de 2 a 5 vezes!",
    },
  "За каждого приглашенного друга вы получите 30 жемчуга — как для себя, так и для друга. За каждого друга с Telegram Premium вы получите 50 жемчуга.":
    {
      ru: "За каждого третьего приглашенного друга вы получите 1 ключ. С его помощью можно открыть сундук с множителем, который увеличит количество добываемых ресурсов в 2-5 раз.",
      en: "For every third invited friend, you will receive 1 key. This key can be used to open a chest with a multiplier that increases the amount of resources collected by 2 to 5 times.",
      fr: "Pour chaque ami que vous invitez, vous recevrez 30 perles, à la fois pour vous-même et pour votre ami. Pour chaque ami avec Telegram Premium, vous recevrez 50 perles.",
      es: "Por cada amigo que invites, recibirás 30 perlas, tanto para ti como para tu amigo. Por cada amigo con Telegram Premium, recibirás 50 perlas.",
      "es-l": "",
      id: "Untuk setiap teman yang kamu undang, kamu akan diberikan 30 mutiara - untukmu dan temanmu. Untuk setiap teman yang memiliki Telegram Premium, kamu akan mendapatkan 50 mutiara.",
      "pt-br":
        "Para cada amigo que você convidar, você receberá 30 pérolas - para você e seu amigo. Para cada amigo com o Telegram Premium, você receberá 50 pérolas.",
    },
  "За опубликованную сторис в Telegram вы": {
    ru: "За опубликованную сторис в Telegram вы",
    en: "For posting a story on Telegram, you",
    fr: "Pour les histoires publiées dans Telegram, vous",
    es: "Por publicar una historia en Telegram, tú",
    "es-l": "",
    id: "Untuk memposting story di Telegram, kamu",
    "pt-br": "Por postar uma história no Telegram, você",
  },
  "Заберите 1 ключ бесплатно!": {
    ru: "Заберите 1 ключ бесплатно!",
    en: "Get 1 key for free!",
    fr: "Obtenez 1 clé gratuitement !",
    es: "¡Recoge gratis 1 llave!",
    "es-l": "",
    id: "Mendapatkan 1 kunci gratis!",
    "pt-br": "Recebe 1 chave grátis!",
  },
  "Забирайте всё автоматически": {
    ru: "Забирайте всё автоматически",
    en: "Take everything automatically",
    fr: "Collecter automatiquement tout",
    es: "Recoge todo automáticamente",
    "es-l": "",
    id: "Ambil semuanya secara otomatis",
    "pt-br": "Pegue tudo de forma automática",
  },
  "Здания приносят только золото.": {
    ru: "Здания приносят только золото.",
    en: "Buildings only bring gold.",
    fr: "Les bâtiments n'apportent que de l'or.",
    es: "Los edificios solo aportan oro.",
    "es-l": "",
    id: "Bangunan hanya menghasilkan emas.",
    "pt-br": "Os edifícios só geram ouro.",
  },
  "Золото.": {
    ru: "Золото.",
    en: "Gold",
    fr: "Or.",
    es: "Oro.",
    "es-l": "",
    id: "Emas",
    "pt-br": "Ouro",
  },
  и: {
    ru: "и",
    en: "and",
    fr: "et",
    es: "y",
    "es-l": "",
    id: "dan",
    "pt-br": "e",
  },
  "и получи": {
    ru: "и получи",
    en: "get",
    fr: "et obtiens",
    es: "y obtén",
    "es-l": "",
    id: "dapatkan",
    "pt-br": "receber",
  },
  "из 3 продлений": {
    ru: "из 3 продлений",
    en: "and 3 renewals",
    fr: "de 3 extensions",
    es: "de 3 prórrogas",
    "es-l": "",
    id: "dan 3 perpanjangan",
    "pt-br": "e 3 renovações",
  },
  "Изначально на вашем острове есть 4 здания и 1 порт, которые необходимо развивать. Для этого вам понадобятся золото, а начиная с второго уровня еще и жемчуг. С каждым уровнем для улучшения потребуется больше ресурсов, но и прокаченные здания будут приносить больше ресурсов.":
    {
      ru: "Изначально на вашем острове есть 4 здания и 1 порт, которые необходимо развивать. Для этого вам понадобится золото, а начиная со второго уровня еще и жемчуг. С каждым уровнем для улучшения зданий требуется большее количество ресурсов, равно как и прокаченные здания будут приносить больше ресурсов.",
      en: "At the beginning there are 4 buildings and a harbour on your island, which you have to develop. To do this, you need gold, and from the second level you need pearls. Each level requires more resources to develop the buildings, as well as the upgraded buildings will bring more resources.",
      fr: "Au départ, il y a 4 bâtiments et 1 port sur votre île à développer. Pour ce faire, vous aurez besoin d'or, et à partir du deuxième niveau, de perles. À chaque niveau suivant, plus de ressources sont nécessaires pour améliorer les bâtiments, de même que les bâtiments développés apporteront plus de ressources.",
      es: "Inicialmente en tu isla hay 4 edificios y 1 puerto que tienes que desarrollar. Para ello necesitarás oro, y a partir del segundo nivel necesitarás también perlas. A medida que avanzas de nivel, necesitarás más recursos para mejorar los edificios, así como los edificios mejorados te aportarán más recursos.",
      "es-l": "",
      id: "Pada awalnya, ada 4 bangunan dan satu pelabuhan di pulaumu, yang harus kamu kembangkan. Untuk melakukan ini, kamu memerlukan emas, dan dari level kedua kamu memerlukan mutiara. Setiap level membutuhkan lebih banyak sumber daya untuk mengembangkan bangunan, dan bangunan yang ditingkatkan akan menghasilkan lebih banyak sumber daya.",
      "pt-br":
        "No início, existem 4 edifícios e um porto em sua ilha, que você precisa aprimorar. Para isso, você precisa de ouro e, a partir do segundo nível, de pérolas. Cada nível exige mais recursos para aprimorar os edifícios, assim como os edifícios aprimorados gerarão mais recursos.",
    },
  Инструменты: {
    ru: "Инструменты",
    en: "Tools",
    fr: "Outils",
    es: "Herramientas",
    "es-l": "",
    id: "Alat",
    "pt-br": "Ferramentas",
  },
  "Использование ботов для накрутки друзей противоречит правилам Pirate Empire! Ключи и жемчуг начисляются только за реальных друзей. Боты будут исключены из списка приглашенных друзей.":
    {
      ru: "Использование ботов для накрутки друзей противоречит правилам Pirate Empire! Ключи начисляются только за реальных друзей. Боты будут исключены из списка приглашенных друзей.",
      en: "Using bots to inflate the number of friends violates the rules of Pirate Empire! Keys are awarded only for real friends. Bots will be removed from the list of invited friends.",
      fr: "Utiliser des robots pour augmenter le nombre d'amis est contre les règles de l'Empire Pirate ! Les clés et les perles ne sont attribuées que pour des vrais amis. Les robots seront exclus de la liste des amis invités.",
      es: "¡Utilizar bots para conseguir más amigos está prohibido por las reglas de Pirate Empire! Las llaves y las perlas solo se concederán por amigos reales. Los bots serán excluidos de la lista de amigos invitados.",
      "es-l": "",
      id: "Menggunakan bot untuk mendapatkan lebih banyak teman melanggar aturan Pirate Empire! Ingat, kunci dan mutiara hanya diberikan kepada teman sungguhan. Bot akan dihapus dari daftar teman yang diundang.",
      "pt-br":
        "Usar robôs para conseguir mais amigos é contra as regras do Pirate Empire! Lembre-se de que somente os amigos verdadeiros recebem chaves e pérolas. Os robôs serão removidos de sua lista de amigos convidados.",
    },
  "История должна оставаться активной в течение 24 часов.": {
    ru: "История должна оставаться активной в течение 24 часов.",
    en: "The story must be active for 24 hours.",
    fr: "Votre historique doit rester actif pendant 24 heures.",
    es: "La historia debe permanecer activa durante 24 horas.",
    "es-l": "",
    id: "Story harus aktif selama 24 jam.",
    "pt-br": "A história deve ficar ativa por 24 horas.",
  },
  "Ключ позволит открыть сундук и получить множитель х2-х5 для сбора золота и жемчуга!":
    {
      ru: "Ключ позволит открыть сундук и получить множитель х2-х5 для сбора золота и жемчуга!",
      en: "The key will open a chest and give you a x2-x5 multiplier to collect gold and pearls!",
      fr: "La clé vous permettra d'ouvrir le coffre et d'obtenir un multiplicateur x2x5 pour collecter de l'or et des perles !",
      es: "¡La llave te permitirá abrir el cofre y obtener un multiplicador x2-x5 para recoger oro y perlas!",
      "es-l": "",
      id: "Kunci ini akan membuka peti dan memberimu pengganda x2-x5 untuk mengumpulkan emas dan mutiara!",
      "pt-br":
        "A chave abrirá um baú com um multiplicador de x2-x5 para coletar ouro e pérolas!",
    },
  КЛЮЧЕЙ: {
    ru: "КЛЮЧЕЙ",
    en: "KEYS",
    fr: "CLÉS",
    es: "LLAVES",
    "es-l": "",
    id: "KUNCI",
    "pt-br": "CHAVES",
  },
  Ключи: {
    ru: "Ключи",
    en: "KEYS",
    fr: "Clés",
    es: "Llaves",
    "es-l": "",
    id: "KUNCI",
    "pt-br": "CHAVES",
  },
  "Ключи —": {
    ru: "Ключи —",
    en: "KEYS  —",
    fr: "Clés —",
    es: "Llaves —",
    "es-l": "",
    id: "KUNCI —",
    "pt-br": "CHAVES —",
  },
  "Кроме того, с каждого друга вы будете получать пожизненный процент от его заработанного золота и золота его друзей: 20% с его золота и 10% с золота его друзей. Эти проценты не будут вычитаться из заработка друзей, а будут начисляться сверху.":
    {
      ru: "Кроме того, за каждого 10-го приглашенного друга вы получите 5 автосборов, за каждого 50-го — 10 автосборов, а за каждого 100-го — 15 автосборов. Автосборы позволяют автоматически собирать ресурсы, обеспечивая, что вы не упустите момент, когда ваши здания заполнились ресурсами, даже если не успели зайти в игру вовремя.",
      en: "Additionally, for every 10th invited friend, you will receive 5 auto-collections; for every 50th, 10 auto-collections; and for every 100th, 15 auto-collections. Auto-collections allow you to automatically gather resources, ensuring you don’t miss the moment when your buildings are full of resources, even if you couldn’t log in to the game on time.",
      fr: "De plus, de chaque ami, vous recevrez un pourcentage à vie de l'or gagné par lui et l'or de ses amis : 20% de son or et 10% de l'or de ses amis. Ces pourcentages ne seront pas déduits des revenus des amis, mais vous seront attribué indépendamment.",
      es: "Además, de cada amigo recibirás un porcentaje de por vida de su oro y del oro de sus amigos: el 20% de su oro y el 10% del oro de sus amigos. Estos porcentajes no se descontarán de las ganancias de los amigos, sino que se añadirán por encima.",
      "es-l": "",
      id: "Kamu juga akan menerima persentase dari emas setiap teman dan emas teman mereka seumur hidup: 20% dari emasmu dan 10% dari emas temanmu. Persentase ini tidak dipotong dari penghasilan temanmu, tetapi ditambahkan ke dalamnya.",
      "pt-br":
        "Você também terá direito a uma porcentagem vitalícia do ouro de cada amigo e do ouro dos amigos deles: 20% do seu ouro e 10% do ouro dos seus amigos. Essas porcentagens não serão deduzidas dos ganhos de seus amigos, mas sim adicionadas a eles.",
    },
  "Мы внимательно следим за рефералами, которых вы приглашаете, используя несколько объективных показателей, которые мы не раскрываем. Мы настоятельно рекомендуем не использовать неактивных рефералов или ботов, так как за таких пользователей награды не будут начисляться.":
    {
      ru: "Мы внимательно следим за рефералами, которых вы приглашаете, используя несколько объективных показателей, которые мы не раскрываем. Мы настоятельно рекомендуем не использовать неактивных рефералов или ботов, так как за таких пользователей награды не будут начисляться.",
      en: "We closely monitor the referrals you invite using several unbiased metrics that we do not disclose. We strongly recommend that you do not use inactive referrals or bots, as no rewards will be given for such users.",
      fr: "Nous surveillons de près les parrainages que vous invitez, en utilisant quelques indicateurs objectifs que nous ne divulguons pas. Nous vous recommandons fortement de ne pas utiliser de parrainages inactifs ou de robots, car les récompenses ne seront pas attribuées à ces utilisateurs.",
      es: "Controlamos de cerca los referidos que invitas utilizando varias métricas imparciales que no revelamos. Te recomendamos encarecidamente que no utilices referidos inactivos o bots, ya que no se concederán recompensas por dichos usuarios.",
      "es-l": "",
      id: "Kami memantau dengan ketat referral yang kamu undang menggunakan sejumlah metrik yang tidak bias yang tidak kami ungkapkan. Kami sangat menyarankan agar kamu tidak menggunakan referral yang tidak aktif atau bot, karena tidak akan ada hadiah yang diberikan untuk pengguna tersebut.",
      "pt-br":
        "Fazemos um monitoramento rigoroso das indicações que você faz usando várias métricas imparciais que não divulgamos. Recomendamos enfaticamente que você não recorra a indicações inativas ou robôs, pois não serão concedidas recompensas a esses usuários.",
    },
  "На данный момент доступен только один остров, который нужно развивать. В ближайшем будущем появятся новые, но они не откроются просто так — вам предстоит постараться, чтобы получить доступ к ним.":
    {
      ru: "На данный момент доступен только один остров, который нужно развивать. В ближайшем будущем появятся новые, но они не откроются просто так — вам предстоит постараться, чтобы получить доступ к ним.",
      en: "At the moment, there is only one island, which you have to develop. New ones will appear in the near future, but they will not just open up - you will have to try to get access to them.",
      fr: "Actuellement, une seule île est disponible pour le développement. Dans un proche avenir, de nouveaux îles apparaîtront, mais pour y accéder, il va falloir travailler.",
      es: "De momento solo hay una isla disponible, que tienes que desarrollar. En un futuro próximo aparecerán otras nuevas, pero no se abrirán como si nada: tendrás que esforzarte para acceder a ellas.",
      "es-l": "",
      id: "Saat ini, hanya ada satu pulau yang harus kamu kembangkan. Pulau-pulau baru akan muncul dalam waktu dekat, tetapi pulau-pulau itu tidak akan terbuka begitu saja - kamu harus berusaha untuk mendapatkan akses ke pulau-pulau itu.",
      "pt-br":
        "No momento, há apenas uma ilha, que você precisa aprimorar. Novas ilhas aparecerão em breve, mas elas não se abrirão facilmente - é preciso tentar obter acesso a elas.",
    },
  "На данный момент доступно 5 уровней для каждой постройки и порта, но в будущем будут добавлены новые уровни, а также экспедиции и новые острова. Впереди вас ждёт много интересного!":
    {
      ru: "На данный момент доступно 5 уровней для каждой постройки и порта, но в будущем будут добавлены новые уровни, а также экспедиции и новые острова. Впереди вас ждёт много интересного!",
      en: "Currently there are 5 levels available for each building and port, but more levels will be added in the future, as well as expeditions and new islands. There are lots of exciting things ahead of you!",
      fr: "Il existe actuellement 5 niveaux disponibles pour chaque bâtiment et chaque port. Prochainement, de nouveaux niveaux, expéditions et îles seront ajoutés. Vous avez beaucoup de choses intéressantes devant vous !",
      es: "De momento hay 5 niveles disponibles para cada edificio y puerto, pero en un futuro se añadirán nuevos niveles, así como expediciones y nuevas islas. ¡Por delante te esperan muchas cosas interesantes!",
      "es-l": "",
      id: "Saat ini, ada 5 level yang tersedia untuk setiap bangunan dan pelabuhan, namun akan semakin banyak level yang ditambahkan di masa mendatang, begitu pula ekspedisi dan pulau baru. Banyak hal menarik lainnya yang akan menantimu!",
      "pt-br":
        "Atualmente, estão disponíveis 5 níveis para cada edifício e porto, mas outros níveis serão adicionados posteriormente, bem como expedições e novas ilhas. Há muitas coisas interessantes à sua espera!",
    },
  "необходимы для открытия сундуков, из которых вы можете получить множитель x2-x5. Это даст вам возможность добывать ресурсы в 2-5 раз больше, в зависимости от удачи, а это поможет вам оказаться в числе лидеров по добыче золота среди пиратов и иметь больше шансов в аирдропе!":
    {
      ru: "необходимы для открытия сундуков, из которых вы можете получить множитель x2-x5. Это даст вам возможность добывать в 2-5 раз больше ресурсов, а это поможет вам оказаться в числе лидеров по добыче золота среди пиратов и иметь больше шансов в аирдропе!",
      en: "are needed to open chests that give you a x2-x5 multiplier. It gives you the opportunity to mine 2 to 5 times more resources, which makes you the leader of the gold mining community and gives you more opportunities for airdrops.",
      fr: "sont nécessaires pour ouvrir les coffres pour obtenir le multiplicateur x2-x5. Cela vous donnera l’occasion d’extraire 2 à 5 fois plus de ressources, ce qui vous aidera à faire partie des leaders de l’extraction d’or parmi les pirates et à avoir plus de chances en airdrop !",
      es: "son necesarios para abrir cofres de los que puedes obtener un multiplicador x2-x5. ¡Esto te dará la oportunidad de extraer 2-5 veces más recursos, y esto te ayudará a estar entre los líderes en la extracción de oro entre los piratas y tener más oportunidades en el AirDrop!",
      "es-l": "",
      id: "dibutuhkan untuk membuka peti yang memberimu pengganda x2-x5. Pengganda ini memberimu kesempatan untuk menambang sumber daya 2 hingga 5 kali lebih banyak, yang menjadikanmu pemimpin komunitas penambangan emas dan memberimu lebih banyak kesempatan untuk mendapatkan airdrop.",
      "pt-br":
        "são necessárias para abrir baús com multiplicadores x2-x5. Ele lhe dá a oportunidade de minerar de 2 a 5 vezes mais recursos, o que o torna o líder da comunidade de mineração de ouro e lhe dá mais oportunidades para fazer airdrops.",
    },
  "Он используется для улучшения зданий, начиная с третьего уровня. Получить его можно с порта, за приглашение друзей, а также за выполнение заданий, таких как подписка на наши соцсети и другие активности.":
    {
      ru: "Он используется для улучшения зданий, начиная с третьего уровня. Получить его можно с порта и за выполнение заданий, таких как подписка на наши соцсети и другие активности.",
      en: "It is used to upgrade buildings starting from level three. You can obtain it from the harbour and by completing tasks such as subscribing to our social media and other activities.",
      fr: "Il est utilisé pour améliorer les bâtiments à partir du troisième niveau. Vous pouvez l'obtenir depuis le port, pour l'invitation des amis, ainsi que pour des tâches effectuées, telles que l'abonnement à nos réseaux sociaux et à d'autres activités.",
      es: "Se utiliza para mejorar los edificios a partir del tercer nivel. Puedes obtenerlo desde el puerto, por invitar a amigos y por completar tareas como suscribirte a nuestras redes sociales y otras actividades.",
      "es-l": "",
      id: "Digunakan untuk meng-upgrade bangunan mulai dari level ketiga. Kamu bisa mendapatkannya di pelabuhan, melalui referral, dan juga dengan menyelesaikan berbagai tugas seperti mendaftar di media sosial kami dan mengikuti aktivitas lainnya.",
      "pt-br":
        "Ele é usado para aprimorar edifícios a partir do terceiro nível. É possível conseguir o multiplicador no porto, por meio de indicações e também ao concluir tarefas, como inscrever-se em nossas redes sociais e participar de outras atividades.",
    },
  "Оно добывается с ваших зданий на острове и с порта, и используется для их улучшения. Улучшения, в свою очередь, повышают количество ресурсов, которые эти объекты приносят. Золото — основная валюта, которую нельзя купить, его можно только добывать или получать % с приглашенных друзей и их друзей. Количество собранного золота также будет влиять на количество монет, которые вы получите при аирдропе!":
    {
      ru: "Оно добывается с ваших зданий на острове и с порта, и используется для их улучшения. Улучшения, в свою очередь, повышают количество ресурсов, которые эти объекты приносят. Золото — основная валюта, которую нельзя купить, его можно только добывать или получить в качестве награды за ежедневный вход в игру. Количество собранного золота также будет влиять на количество токенов, которые вы получите при аирдропе!",
      en: "It is harvested from your buildings on the island and the harbour and is used to upgrade them. Upgrades, in turn, increase the amount of resources these structures generate. Gold is the primary currency that cannot be purchased; it can only be collected or received as a reward for daily login to the game. The amount of gold collected will also influence the number of tokens you receive during an airdrop!",
      fr: "Il provient de vos bâtiments sur l'île et du port, et sert à les améliorer. Les améliorations, à leur tour, augmentent la quantité de ressources apportée par les bâtiments et les ports. L'or est la devise principale qui ne peut pas être achetée, elle peut uniquement être extraite ou reçue comme pourcentage par des amis invités et leurs amis. La quantité d'or collectée affectera également le nombre de pièces que vous recevrez avec airdrop !",
      es: "Se extrae de tus edificios en la isla y del puerto, y se utiliza para mejorarlos. Las mejoras, a su vez, aumentan la cantidad de recursos que aportan estos objetos. El oro es la moneda principal que no se puede comprar, solo se puede extraer u obtener un % de los amigos invitados y de sus amigos. ¡La cantidad de oro recogido también influirá en la cantidad de monedas que obtendrás en el AirDrop!",
      "es-l": "",
      id: "Emas ditambang dari berbagai bangunanmu di pulau dan pelabuhan. Emas digunakan untuk meng-upgrade bangunan tersebut. Melakukan upgrade, pada gilirannya, akan meningkatkan jumlah sumber daya yang dihasilkan oleh bangunan-bangunan ini. Emas adalah mata uang utama yang tidak dapat dibeli, hanya dapat ditambang atau diperoleh dari referral-mu dan teman-teman mereka. Jumlah emas yang kamu kumpulkan juga akan memengaruhi jumlah koin yang kamu dapatkan saat mendapatkan airdrop!",
      "pt-br":
        "Ele é minerado de seus edifícios na ilha e no porto. Ele é usado para aprimorá-los. As atualizações, por sua vez, aumentam a quantidade de recursos que esses itens trazem. Ouro é a principal moeda e não é possível comprá-lo. Ele só pode ser coletado ou obtido de suas referências e dos amigos delas. A quantidade de ouro que você coletar também afetará a quantidade de moedas que você receberá quando fizer um airdrop!",
    },
  Острова: {
    ru: "Острова",
    en: "Islands",
    fr: "Îles",
    es: "Islas",
    "es-l": "",
    id: "Pulau",
    "pt-br": "Ilhas",
  },
  "ПОДАРОК!": {
    ru: "ПОДАРОК!",
    en: "GIFT!",
    fr: "CADEAU !",
    es: "¡REGALO!",
    "es-l": "",
    id: "HADIAH!",
    "pt-br": "PRESENTE!",
  },
  ПОДЕЛИТЬСЯ: {
    ru: "ПОДЕЛИТЬСЯ",
    en: "SHARE",
    fr: "PARTAGER",
    es: "COMPARTIR",
    "es-l": "",
    id: "BAGIKAN",
    "pt-br": "COMPARTILHAR",
  },
  Подтвердить: {
    ru: "Подтвердить",
    en: "Confirm",
    fr: "Confirmer",
    es: "Confirmar",
    "es-l": "",
    id: "Konfirmasikan",
    "pt-br": "Confirmar",
  },
  Подтверждение: {
    ru: "Подтверждение",
    en: "Confirmation",
    fr: "Confirmation",
    es: "Confirmación",
    "es-l": "",
    id: "Konfirmasi",
    "pt-br": "Confirmação",
  },
  "Позвольте игре работать за вас!": {
    ru: " Позвольте игре работать за вас!",
    en: "Let the game work for you!",
    fr: "Laissez le jeu fonctionner pour vous !",
    es: "¡Deja que el juego trabaje para ti!",
    "es-l": "",
    id: "Biarkan permainan ini bekerja untukmu!",
    "pt-br": "Deixe que o jogo trabalhe para você!",
  },
  "получите 50 жемчуга": {
    ru: "получите 50",
    en: "get 50",
    fr: "obtenir 50",
    es: "obtén 50",
    "es-l": "",
    id: "dapatkan 50",
    "pt-br": "ganhe 50",
  },
  "Помимо аирдропа, среди активных игроков будут проводиться розыгрыши реальных денег, добавляя еще больше возможностей для выигрыша!":
    {
      ru: "Помимо аирдропа, среди активных игроков будут проводиться розыгрыши реальных денег, добавляя еще больше возможностей для выигрыша!",
      en: "In addition to the airdrop, real money drawings will be held among active players for even more chances to win!",
      fr: "En plus d'airdrop, des tirages en argent réel auront lieu parmi les joueurs actifs, ce qui ajoutera encore plus d'opportunités de gagner !",
      es: "¡Además del AirDrop, se celebrarán sorteos de dinero real entre los jugadores activos lo que añadirá aún más oportunidades para ganar!",
      "es-l": "",
      id: "Selain airdrop, undian berhadiah uang sungguhan akan diadakan di antara pemain aktif untuk peluang menang yang semakin besar!",
      "pt-br":
        "Além do airdrop, serão realizados sorteios com dinheiro vivo entre os jogadores ativos para aumentar ainda mais as chances de ganhar!",
    },
  "Попробуйте АвтоСбор бесплатно.": {
    ru: "Попробуйте АвтоСбор бесплатно.",
    en: "Try Auto - Collect for free.",
    fr: "Essayez AutoCollect gratuitement.",
    es: "Prueba Auto-recoger gratis.",
    "es-l": "",
    id: "Coba Pengumpulan - Otomatis gratis.",
    "pt-br": "Experimente a Coleta automática gratuitamente.",
  },
  "Порт приносит как золото, так и жемчуг.": {
    ru: "Порт приносит как золото, так и жемчуг.",
    en: "The port brings both gold and pearls.",
    fr: "Le port apporte à la fois de l'or et des perles.",
    es: "El puerto aporta tanto oro como perlas.",
    "es-l": "",
    id: "Pelabuhan menghasilkan emas dan mutiara.",
    "pt-br": "O porto oferece ouro e pérolas.",
  },
  "Почувствуйте, как удобно открывать сундуки.": {
    ru: "Посмотрите, какую выгоду приносят сундуки.",
    en: "Look at the benefits that chests bring.",
    fr: "Découvrez les avantages des coffres.",
    es: "Mira qué ventajas aportan los cofres.",
    "es-l": "",
    id: "Lihat berbagai keuntungan peti.",
    "pt-br": "Veja os benefícios dos baús.",
  },
  "Представьте, сколько золота вы сможете накопить, приглашая больше друзей, которые будут зарабатывать для вас!":
    {
      ru: "Представьте, насколько больше ресурсов вы сможете добывать, просто приглашая друзей!",
      en: "Imagine how much resources you can earn by just inviting friends!",
      fr: "Imaginez combien d'or vous pouvez accumuler en invitant plus d'amis qui vont le gagner pour vous !",
      es: "¡Imagina cuánto oro puedes acumular invitando a más amigos que ganarán por ti!",
      "es-l": "",
      id: "Bayangkan berapa banyak emas yang bisa kamu dapatkan dengan mengundang lebih banyak teman untuk menghasilkan uang untukmu!",
      "pt-br":
        "Imagine a quantidade de ouro que você pode ganhar convidando mais amigos para ganhar para você!",
    },
  "При многократном нарушении правил мы имеем право заблокировать аккаунт!": {
    ru: "При многократном нарушении правил мы имеем право заблокировать аккаунт!",
    en: "If you repeatedly violate the rules, we reserve the right to suspend your account!",
    fr: "Si vous enfreignez les règles à plusieurs reprises, nous avons le droit de bloquer votre compte !",
    es: "Si infringes las reglas repetidamente nos reservamos el derecho de bloquear tu cuenta",
    "es-l": "",
    id: "Jika kamu berulang kali melanggar peraturan, kami berhak menangguhkan akunmu!",
    "pt-br":
      "Caso você viole sistematicamente as regras, reservamo-nos o direito de suspender sua conta!",
  },
  "При публикации не удаляйте ссылку с картинки и из описания, иначе задание не будет засчитано.":
    {
      ru: "При публикации не удаляйте ссылку с картинки и из описания, иначе задание не будет засчитано.",
      en: "Do not remove the link from the image or description when posting. Otherwise, the assignment will not be credited.",
      fr: "Lors de la publication, ne supprimez pas le lien de l'image et de la description, sinon la tâche ne sera pas validée.",
      es: "Al publicar, no elimines el enlace de la imagen ni de la descripción, de lo contrario la tarea no se acreditará.",
      "es-l": "",
      id: "Jangan menghapus tautan dari gambar atau deskripsi saat memposting. Jika tidak, tugas tidak akan diakui.",
      "pt-br":
        "Não remova o link da imagem ou da descrição ao publicar. Caso contrário, os créditos da atribuição não serão concedidos.",
    },
  "Приглашай друзей и получай ключи!": {
    ru: "Приглашай друзей и получай ключи!",
    en: "Invite friends and get keys!",
    fr: "Invitez des amis et obtenez les clés !",
    es: "¡Invita a tus amigos y obtén llaves!",
    "es-l": "",
    id: "Undang teman dan dapatkan kunci!",
    "pt-br": "Convide amigos e ganhe chaves!",
  },
  "Приглашайте друзей и зарабатывайте!": {
    ru: "Приглашайте друзей и зарабатывайте!",
    en: "Invite friends and earn!",
    fr: "Invitez des amis et gagnez !",
    es: "¡Invita a tus amigos y gana!",
    "es-l": "",
    id: "Undang teman dan dapatkan penghasilan!",
    "pt-br": "Convide amigos e ganhe!",
  },
  "Приглашая друзей, вы можете оказаться в первых рядах среди пиратов. За каждого приведённого друга вы получаете 30 жемчуга, а если у друга есть Telegram Premium, то 50 жемчуга. За каждого 10-го приглашённого друга вы получаете 1 ключ для сундуков.":
    {
      ru: "Приглашая друзей, вы можете оказаться в первых рядах среди пиратов. За каждого третьего приглашенного друга вы получите 1 ключ. С его помощью можно открыть сундук с множителем, который увеличит количество добываемых ресурсов в 2-5 раз.",
      en: "By inviting friends, you can get to the top of the pirate rankings. For every third friend you invite, you will receive 1 key. This key can be used to open a chest with a multiplier that increases the amount of resources collected by 2 to 5 times.",
      fr: "En invitant des amis, vous pouvez être à l'avant-garde des pirates. Pour chaque ami que vous amenez, vous obtenez 30 perles, et si votre ami a Telegram Premium, vous obtenez 50 perles. Pour chaque 10-ème ami que vous invitez, vous obtenez 1 clé de coffre.",
      es: "Invitando a amigos, puedes llegar a lo más alto de la clasificación pirata. Por cada amigo que invites, recibirás 30 perlas, y si el amigo tiene una cuenta Telegram Premium, obtendrás 50 perlas. Por cada 10 amigos que invites, recibirás 1 llave para cofres.",
      "es-l": "",
      id: "Dengan mengundang teman, kamu bisa mencapai peringkat teratas bajak laut. Untuk setiap teman yang kamu undang, kamu akan menerima 30 mutiara, dan jika teman tersebut memiliki akun Telegram Premium, kamu akan mendapatkan 50 mutiara. Untuk setiap teman ke-10 yang kamu undang, kamu akan mendapatkan 1 kunci peti.",
      "pt-br":
        "Ao convidar amigos, você pode conquistar o topo do ranking de piratas. Para cada amigo que convidar, você receberá 30 pérolas e, se o amigo tiver uma conta Telegram Premium, você receberá 50 pérolas. A cada 10 amigos que você convidar, você receberá 1 chave para baús.",
    },
  "Проверка выполнения задания осуществляется ежедневно. За многократное нарушение мы можем заблокировать аккаунт. Выполняйте задание честно!":
    {
      ru: "Проверка выполнения задания осуществляется ежедневно. За многократное нарушение мы можем заблокировать аккаунт. Выполняйте задание честно!",
      en: "Review of assignment completion is done daily. We may suspend your account for multiple violations. Do the assignment honestly!",
      fr: "La tâche est vérifiée quotidiennement. En cas de violations multiples, nous pouvons suspendre votre compte. Effectuez l’activité en toute honnêteté !",
      es: "La revisión del cumplimiento de las tareas se realiza diariamente. Podemos bloquear tu cuenta si cometes varias infracciones. ¡Haz la tarea con honestidad!",
      "es-l": "",
      id: "Peninjauan penyelesaian tugas dilakukan setiap hari. Kami dapat menangguhkan akunmu karena beberapa pelanggaran. Kerjakan tugas dengan jujur!",
      "pt-br":
        "O cumprimento da tarefa é revisado diariamente. Podemos suspender sua conta em caso de múltiplas violações. Realize a tarefa de forma honesta!",
    },
  Продолжить: {
    ru: "Продолжить",
    en: "Continue",
    fr: "Continuer",
    es: "Continuar",
    "es-l": "",
    id: "Lanjutkan",
    "pt-br": "Continuar",
  },
  "Работа автобустеров закончена!": {
    ru: "Работа синхронного автосбора завершена! Было получено на 30% больше ресурсов. Поздравляем!",
    en: "The work of sync auto - collect is finished! 30% more resources have been obtained. Congratulations!",
    fr: "L'autocollecte synchrone est terminé ! 30 % de ressources supplémentaires ont été obtenues. Félicitations !",
    es: "¡El trabajo de auto-recoger sincronizado ha terminado! Se ha obtenido un 30% más de recursos. ¡Enhorabuena!",
    "es-l": "",
    id: "Pengumpulan - otomatis tersinkronisasi selesai! 30% lebih banyak sumber daya telah diperoleh. Selamat!",
    "pt-br":
      "O trabalho de sincronização da coleta automática foi concluído! Um aumento de 30% nos recursos foi obtido. Parabéns!",
  },
  "Работает синхронный автосбор": {
    ru: "Работает синхронный автосбор",
    en: "Sync auto - collect is running",
    fr: "L'autocollecte synchrone est en cours",
    es: "Trabaja el auto-recoger sincronizado",
    "es-l": "",
    id: "Pengumpulan - otomatis tersinkronisasi sedang berjalan",
    "pt-br": "A sincronização da coleta automática está em execução",
  },
  "С зданий необходимо собирать золото каждые 3 часа, с порта - каждый час. Кроме того, нужно отправлять корабль за новыми ресурсами. Если этого не делать, новые ресурсы не будут собираться.":
    {
      ru: "С зданий необходимо собирать золото каждые 3 часа, с порта - каждый час. Кроме того, нужно отправлять корабль за новыми ресурсами. Если этого не делать, добыча ресурсов будет приостановлена.",
      en: "You need to collect gold from the buildings every 3 hours and from the port every hour. You must also send a ship to collect new resources. If you don't do this, the resource extraction will be stopped.",
      fr: "L’or doit être collecté dans les bâtiments toutes les 3 heures et dans le port toutes les heures. Vous devez également envoyer le navire pour obtenir de nouvelles ressources. Le non-respect de cette consigne arrêtera l’extraction des ressources.",
      es: "Tienes que recoger oro de los edificios cada 3 horas, del puerto cada hora. Además, tienes que enviar el barco por nuevos recursos. Si no lo haces, la extracción de recursos se interrumpirá.",
      "es-l": "",
      id: "Kamu perlu mengumpulkan emas dari bangunan setiap 3 jam dan dari pelabuhan setiap jam. Kamu juga harus mengirim kapal untuk mengumpulkan sumber daya baru. Jika kamu tidak melakukan ini, ekstraksi sumber daya akan dihentikan.",
      "pt-br":
        "Você precisa coletar ouro dos edifícios a cada 3 horas e do porto a cada hora. Você também deve enviar um navio para coletar novos recursos. Caso contrário, a extração de recursos será interrompida.",
    },
  "с помощью 10 АвтоСборов и получите 1 ключ для сундука множителя в подарок": {
    ru: "с помощью 10 АвтоСборов и получите 1 ключ для сундука множителя в подарок",
    en: "with the help of 10 auto - collect operations and get 1 multiplier chest key as a gift",
    fr: "avec 10 AutoCollectes et obtenez 1 clé pour le coffre multiplicateur en cadeau",
    es: "con ayuda de 10 Auto-recoger y obtén 1 llave para el cofre del multiplicador como regalo",
    "es-l": "",
    id: "dengan bantuan 10 operasi pengumpulan - otomatis dan dapatkan 1 kunci peti pengganda sebagai hadiah",
    "pt-br":
      "com a ajuda de 10 operações de coleta automática e receba 1 chave de baú multiplicador como presente",
  },
  "Сегодня ваш день, капитан!": {
    ru: "Сегодня ваш день, капитан!",
    en: "Today is your day, Captain!",
    fr: "Aujourd'hui est votre jour, Capitaine !",
    es: "¡Hoy es tu día, capitán!",
    "es-l": "",
    id: "Hari ini adalah harimu, Kapten!",
    "pt-br": "Hoje é seu dia, Capitão!",
  },
  "Синхронный сбор": {
    ru: "Синхронный сбор",
    en: "Sync auto - collect",
    fr: "Collecte synchrone",
    es: "Recogida sincronizada",
    "es-l": "",
    id: "Pengumpulan - otomatis tersinkronisasi",
    "pt-br": "Sincronizar coleta automática",
  },
  скидка: {
    ru: "Скидка",
    en: "Discount.",
    fr: "Remise",
    es: "Descuento",
    "es-l": "",
    id: "Diskon.",
    "pt-br": "Desconto.",
  },
  "скидка 400%": {
    ru: "скидка 400%",
    en: "400% off",
    fr: "400 % de réduction",
    es: "400% de descuento",
    "es-l": "",
    id: "potongan 400%",
    "pt-br": "400% de desconto",
  },
  "Следующее продление будет стоить": {
    ru: "Следующее продление будет стоить ",
    en: "The next renewal will cost",
    fr: "Le prochain renouvellement coûtera",
    es: "La próxima renovación costará",
    "es-l": "",
    id: "Perpanjangan berikutnya akan dikenakan biaya",
    "pt-br": "A próxima renovação terá um custo de",
  },
  "Специальное предложение": {
    ru: "Специальное предложение",
    en: "Special offer",
    fr: "Offre spéciale",
    es: "Oferta especial",
    "es-l": "",
    id: "Penawaran spesial",
    "pt-br": "Oferta especial",
  },
  "Специальное предложение!": {
    ru: "Специальное предложение!",
    en: "Special offer!",
    fr: "Offre spéciale !",
    es: "¡Oferta especial!",
    "es-l": "",
    id: "Penawaran spesial!",
    "pt-br": "Oferta especial!",
  },
  "Сундук Адмирала": {
    ru: "Сундук Адмирала",
    en: "Admiral's Chest",
    fr: "Coffre de l'amiral",
    es: "Cofre del Almirante",
    "es-l": "",
    id: "Peti Laksamana",
    "pt-br": "Baú do Almirante",
  },
  "Сундук Капитана": {
    ru: "Сундук Капитана",
    en: "Captain's Chest",
    fr: "Coffre du capitaine",
    es: "Cofre del Capitán",
    "es-l": "",
    id: "Peti Kapten",
    "pt-br": "Baú do Capitão",
  },
  "Сундук Матроса": {
    ru: "Сундук Матроса",
    en: "Sailor's Chest",
    fr: "Coffre de marin",
    es: "Cofre del Marinero",
    "es-l": "",
    id: "Peti Pelaut",
    "pt-br": "Baú do Marinheiro",
  },
  "Сундук Юнги": {
    ru: "Сундук Юнги",
    en: "Cabin boy's Chest",
    fr: "Coffre de moussaillon",
    es: "Cofre del Grumete",
    "es-l": "",
    id: "Peti Anak Kabin",
    "pt-br": "Baú do Camareiro",
  },
  "Также при синхронном сборе вы получите на 30% больше ресурсов!": {
    ru: "Также при синхронном сборе вы получите на 30% больше ресурсов!",
    en: "You'll also get 30% more resources with sync auto-collect. ",
    fr: "De plus, avec la collecte synchrone, vous obtiendrez 30 % de ressources en plus !",
    es: "¡También obtendrás un 30% más de recursos con la sincronización automática!",
    "es-l": "",
    id: "Kamu juga akan mendapatkan 30% lebih banyak sumber daya dengan pengumpulan otomatis tersinkronisasi.",
    "pt-br":
      "Você também receberá 30% a mais de recursos com a sincronização da coleta automática.",
  },
  "У вас нет автосборов для активации": {
    ru: "У вас нет автосборов для активации",
    en: "You do not have auto - collects to activate",
    fr: "Vous n'avez pas d'autocollectes à activer",
    es: "No tienes auto-recoger para activar",
    "es-l": "",
    id: "Kamu tidak memiliki pengumpulan - otomatis yang bisa diaktifkan",
    "pt-br": "Você não tem coletas automáticas para ativar",
  },
  Удвоение: {
    ru: "Удвоение",
    en: "Duplication",
    fr: "Doublement",
    es: "Duplicación",
    "es-l": "",
    id: "Duplikasi",
    "pt-br": "Duplicação",
  },
  "Управление островом": {
    ru: "Управление островом",
    en: "Island management",
    fr: "Gestion des îles",
    es: "Gestión de la isla",
    "es-l": "",
    id: "Manajemen pulau",
    "pt-br": "Gerenciamento das ilhas",
  },
  "Успейте воспользоваться скидкой!": {
    ru: "Успейте воспользоваться скидкой!",
    en: "Take advantage of the discount!",
    fr: "Profitez de la réduction !",
    es: "¡Date prisa y aprovecha el descuento!",
    "es-l": "",
    id: "Manfaatkan diskonnya!",
    "pt-br": "Não perca o desconto!",
  },
  "Чем больше активности, золота и покупок, тем выше ваш шанс получить ценные монеты в аирдропе. Покупки в игре будут играть важную роль, так как активные покупатели будут иметь приоритет при распределении наград.":
    {
      ru: "Чем больше активности, золота и покупок, тем выше ваш шанс получить ценные монеты в аирдропе. Покупки в игре будут играть важную роль, так как активные покупатели будут иметь приоритет при распределении наград.",
      en: "The more activity, gold, and purchases you make, the better your chances of earning valuable coins in the airdrop. In-game purchases will play an important role, as active buyers will be prioritized for rewards.",
      fr: "Plus il y a d'activité, d'or et d'achats, plus vous avez de chances d'obtenir des pièces de valeur pendant airdrop. Les achats ont un rôle important pendant le jeu, car les acheteurs actifs auront la priorité lors de la distribution des récompenses.",
      es: "Cuanta más actividad, oro y compras, más posibilidades tendrás de obtener monedas valiosas en el AirDrop. Las compras en el juego desempeñarán un papel importante, ya que los compradores activos tendrán prioridad al distribuir las recompensas.",
      "es-l": "",
      id: "Semakin banyak aktivitas, emas, dan pembelian yang kamu lakukan, semakin besar peluangmu untuk mendapatkan koin berharga di airdrop. Pembelian dalam game akan memainkan peran penting, karena pembeli yang aktif akan diprioritaskan untuk mendapatkan hadiah.",
      "pt-br":
        "Quanto mais atividades, ouro e compras você fizer, maiores serão suas chances de ganhar moedas valiosas no airdrop. As compras realizadas durante o jogo desempenharão um papel importante, pois os compradores ativos terão prioridade nas recompensas.",
    },
  'Это бустер "Ключ", он позволяет открывать сундуки, из которых можно получить бустер "Множитель" ':
    {
      ru: 'Это "Ключ", он позволяет открывать сундуки, из которых можно получить бустер "Множитель" ',
      en: 'This is the “Key”, it allows you to open chests where you can get the "Multiplier" booster from it ',
      fr: 'C\'est la "Clé", elle vous permet d\'ouvrir des coffres qui contiennent des boosters "Multiplicateur"',
      es: 'Esta "Llave" te permite abrir los cofres de los que puedes obtener el booster "Multiplicador".',
      "es-l": "",
      id: "Ini adalah “Kunci”, kunci ini memungkinkanmu untuk membuka peti di mana kamu bisa mendapatkan booster “Pengganda” dari dalamnya",
      "pt-br":
        "Essa é a “Chave”, que permite abrir baús nos quais você pode obter o booster “Multiplicador”",
    },
  "это уникальный инструмент, который экономит ваше время. Активировав его, вы можете быть уверены, что не пропустите момент, когда ваши здания заполнились ресурсами, даже если не успели зайти в игру вовремя.":
    {
      ru: "это уникальный инструмент, который экономит ваше время. Активировав его, вы можете быть уверены, что не пропустите момент, когда ваши здания заполнились ресурсами, даже если не успели зайти в игру вовремя.",
      en: "is a unique time-saving tool. By activating it, you can be sure that you won't miss the moment when your buildings are filled with resources, even if you didn't get into the game in time.",
      fr: "c'est un outil unique qui vous fait gagner du temps. En l'activant, vous pouvez être sûr de ne pas manquer le moment où vos bâtiments sont remplis de ressources, même si vous avez manqué le début du jeu.",
      es: "es una herramienta única que te ahorra tiempo. Activándola, puedes asegurarte de no perderte el momento en que tus edificios se llenen de recursos, aunque no hayas iniciado sesión en el juego a tiempo.",
      "es-l": "",
      id: "adalah alat penghemat waktu yang unik. Dengan mengaktifkannya, kamu takkan melewatkan saat bangunanmu dipenuhi dengan sumber daya, bahkan jika kamu tidak masuk ke dalam game pada waktunya.",
      "pt-br":
        "é uma ferramenta extraordinária para economizar tempo. Ao ativá-la, você garante que não perderá o exato momento em que seus edifícios estiverem cheios de recursos, mesmo que não esteja jogando.",
    },
  "ЕЖЕДНЕВНАЯ НАГРАДА": {
    ru: "ЕЖЕДНЕВНАЯ НАГРАДА",
    en: "DAILY REWARD",
    fr: "RÉCOMPENSE QUOTIDIENNE",
    es: "RECOMPENSA DIARIA",
    "es-l": "",
    id: "HADIAH HARIAN",
    "pt-br": "RECOMPENSA DIÁRIA",
  },
  "Награда за день": {
    ru: "Награда за день",
    en: "Raward for the day",
    fr: "Récompense d'aujourd'hui",
    es: "Recompensa del día",
    "es-l": "",
    id: "Hadiah untuk hari ini",
    "pt-br": "Recompensa do dia",
  },
  РЕКЛАМА: {
    ru: "РЕКЛАМА",
    en: "ADVERTISEMENT",
    fr: "PUBLICITÉ",
    es: "PUBLICIDAD",
    "es-l": "",
    id: "IKLAN",
    "pt-br": "ANÚNCIO",
  },
  "Ваша награда:": {
    ru: "Ваша награда:",
    en: "Your reward:",
    fr: "Votre récompense :",
    es: "Tu recompensa:",
    "es-l": "",
    id: "Hadiahmu:",
    "pt-br": "Sua recompensa:",
  },
  Смотреть: {
    ru: "Смотреть",
    en: "Check",
    fr: "Voir",
    es: "Ver",
    "es-l": "",
    id: "Lihat",
    "pt-br": "Verificar",
  },
  "Посмотри рекламу и получите подарок!": {
    ru: "Посмотри рекламу и получи награду!",
    en: "Watch an ad and get a reward!",
    fr: "Consultez la récompense et obtenez :",
    es: "Mira la recompensa y obtén:",
    "es-l": "",
    id: "Lihat hadiahnya dan dapatkan:",
    "pt-br": "Verifique o prêmio e receba:",
  },
  "Просмотрите рекламу ещё": {
    ru: "Посмотрите рекламу ещё",
    en: "Watch another ad",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "раз, чтобы получить уникальный бонус": {
    ru: "раз, чтобы получить уникальный бонус",
    en: "to receive a unique bonus",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "за каждого 3-го друга!": {
    ru: "за каждого 3-го друга!",
    en: "for every 3rd friend!",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  Сегодня: {
    ru: "Сегодня",
    en: "Today",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  День: {
    ru: "День",
    en: "Day",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Если вы пропустите один день, то потеряете прогресс": {
    ru: "Пропуск хотя бы одного дня приведет к потере прогресса",
    en: "Missing even one day will result in losing progress.",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  просмотренно: {
    ru: "Просмотрено",
    en: "Watched",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "У вас максимальное количество бустеров!": {
    ru: "У вас максимальное количество множителей!",
    en: "You have the maximum number of multipliers!",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "В будущем появится новый тип сундуков, для которых также понадобятся ключи, включая те, которые откроют новые острова! Ключи можно купить в магазине или получить за каждого 10-го приглашённого друга.":
    {
      ru: "В будущем появится новый тип сундуков, для которых также понадобятся ключи, включая те, которые откроют новые острова! Ключи можно купить в магазине или получить за каждого 3-го приглашённого друга.",
      en: "A new type of chest will be introduced in the future that will also require keys, including those that unlock new islands! Keys can be purchased in the store or earned for every 3rd invited friend.",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      "pt-br": "",
    },
  "Малый Сундук Сокровищ": {
    ru: "Малый Сундук Сокровищ",
    en: "Small Treasure Chest",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Первое плавание": {
    ru: "Первое плавание",
    en: "First Voyage",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Средний Сундук Сокровищ": {
    ru: "Средний Сундук Сокровищ",
    en: "Medium Treasure Chest",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Захватчик Морей": {
    ru: "Захватчик Морей",
    en: "Sea Conqueror",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Большой Сундук Сокровищ": {
    ru: "Большой Сундук Сокровищ",
    en: "Large Treasure Chest",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Легендарный Сундук": {
    ru: "Легендарный Сундук",
    en: "Legendary Chest",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Гордость Посейдона":{
    ru:"Гордость Посейдона",
    en:"Glory of Poseidon",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Пиратское Сокровище":{
    ru:"Пиратское Сокровище",
    en:"Pirate Treasure",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "БАГ РЕПОРТ":{
    ru:"БАГ РЕПОРТ",
    en:"BUG REPORT",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Баг-репорт успешно отправлен":{
    ru:"Баг-репорт успешно отправлен",
    en:"Bug report successfully sent",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Произошла ошибка, попробуйте ещё раз":{
    ru:"Произошла ошибка, попробуйте ещё раз",
    en:"An error occurred, please try again",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Подробнее о ежедневном игровом рейтинге": {
    ru: "Подробнее о ежедневном игровом рейтинге",
    en: "Learn more about the daily game leaderboard",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Осталось до раздачи TON": {
    ru: "Осталось до раздачи TON",
    en: "Time left until the TON distribution",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Пригласите 50 друзей": {
    ru: "Пригласите 50 друзей",
    en: "Invite 50 friends",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Пригласите 100 друзей": {
    ru: "Пригласите 100 друзей",
    en: "Invite 100 friends",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Нету доступных квестов...": {
    ru: "Нет доступных квестов...",
    en: "No available quests...",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "СОЦИАЛЬНЫЕ СЕТИ": {
    ru: "СОЦИАЛЬНЫЕ СЕТИ",
    en: "SOCIAL NETWORKS",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Загрузка рекламы...": {
    ru: "Загрузка рекламы...",
    en: "Loading ads...",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Нажмите на баннер и получите награду!": {
    ru: "Нажмите на баннер и получите награду!",
    en: "Click on the banner to get your reward!",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "СТАРТОВЫЙ НАБОР": {
    ru: "СТАРТОВЫЙ НАБОР",
    en: "STARTER PACK",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Выгода 400%! Это поможет вам продвинуться в рейтинге!": {
    ru: "Выгода 400%! Это поможет вам продвинуться в рейтинге!",
    en: "400% benefit! This will help you advance in the leaderboard!",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  КОШЕЛЁК: {
    ru: "КОШЕЛЕК",
    en: "WALLET",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Вывод денег": {
    ru: "Вывод денег",
    en: "Withdraw funds",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "ВЫВОД ДЕНЕГ": {
    ru: "ВЫВОД ДЕНЕГ",
    en: "WITHDRAW FUNDS",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Сумма наград за всё время:": {
    ru: "Сумма наград за всё время:",
    en: "Total rewards amount:",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Сумма наград сегодня:": {
    ru: "Сумма наград сегодня:",
    en: "",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Подключите свой TON кошелек для вывода выигрышей с ежедневного рейтинга": {
    ru: "Подключите свой TON кошелек для вывода выигрышей с ежедневного рейтинга",
    en: "Connect your TON wallet to withdraw winnings from the daily leaderboard",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Подключить кошелёк": {
    ru: "Подключить кошелек",
    en: "Connect Wallet",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  Вывести: {
    ru: "Вывести",
    en: "Withdraw",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Минимальная сумма вывода 0.01": {
    ru: "Минимальная сумма вывода 0.1",
    en: "Minimum withdrawal amount: 0.1",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  ОК: {
    ru: "ОК",
    en: "OK",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "СУММА НАГРАД СЕГОДНЯ": {
    ru: "СУММА НАГРАД СЕГОДНЯ",
    en: "REWARD AMOUNTS TODAY",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "ТОП 50 игроков по золоту": {
    ru: "ТОП 50 игроков по золоту",
    en: "Top 50 Players by Gold",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Просматривай рекламу, зарабатывай жемчуг и попади в рейтинг. Активные игроки забирают самое ценное!":
    {
      ru: "Просматривай рекламу, зарабатывай жемчуг и попади в рейтинг. Активные игроки забирают самое ценное!",
      en: "Watch ads, earn pearls, and climb the leaderboard. Active players take the most valuable rewards!",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      "pt-br": "",
    },
  "ТОП 50 игроков по жемчугу": {
    ru: "ТОП 50 игроков по жемчугу",
    en: "Top 50 Players by Pearls",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Собирай золото с островов и занимай место в рейтинге. Чем больше золота, тем выше твоя награда!":
    {
      ru: "Собирай золото с островов и занимай место в рейтинге. Чем больше золота, тем выше твоя награда!",
      en: "Collect gold from the islands and claim your spot in the leaderboard. The more gold you collect, the higher your reward!",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      "pt-br": "",
    },
  "ПОДРОБНЕЕ О ЕЖЕДНЕВНОМ ИГРОВОМ РЕЙТИНГЕ": {
    ru: "ПОДРОБНЕЕ О ЕЖЕДНЕВНОМ ИГРОВОМ РЕЙТИНГЕ",
    en: "LEARN MORE ABOUT THE DAILY GAME LEADERBOARD",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Каждый день раздача TON за активность в игре. Заходи и забирай своё!": {
    ru: "Каждый день раздача TON за активность в игре. Заходи и забирай своё!",
    en: "TON is distributed for game activity every day. Log in and claim yours!",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "💰 В Pirate Empire каждый день раздают TON за активность в игре. Лови момент!":
    {
      ru: "💰 В Pirate Empire каждый день раздают TON за активность в игре. Лови момент!",
      en: "💰TON is distributed daily for game activity in Pirate Empire. Don’t miss out!",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      "pt-br": "",
    },
  "Пока ты читаешь это, другие забирают TON. \nРесурсы накопились, корабль с сокровищами пришёл, а ТОП-50 уже полон активных игроков. Хочешь быть среди них? Заходи и покажи, на что ты способен!":
    {
      ru: "Пока ты читаешь это, другие забирают TON. \nРесурсы накопились, корабль с сокровищами пришёл, а ТОП-50 уже полон активных игроков. Хочешь быть среди них? Заходи и покажи, на что ты способен!",
      en: "While you're reading this, others are claiming TON.  \nResources are piling up, the treasure ship has arrived, and the Top 50 is already filling with active players. Want to be one of them? Log in and show what you're capable of!",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      "pt-br": "",
    },
  "Упустишь TON? \nКорабль с сокровищами причалил, твои здания ломятся от ресурсов. Вопрос один: ты успеешь в ТОП-50? TON уносят только самые быстрые!\n":
    {
      ru: "Упустишь TON? \nКорабль с сокровищами причалил, твои здания ломятся от ресурсов. Вопрос один: ты успеешь в ТОП-50? TON уносят только самые быстрые!\n",
      en: "Will you miss out on TON?  \nThe treasure ship has docked, your buildings are overflowing with resources. The question is: will you make it to the Top 50? Only the fastest take the TON!",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      "pt-br": "",
    },
  "TON достанутся только лучшим! \nКорабль вернулся с добычей, здания полны ресурсов, а ТОП-50 уже почти заполнен. Ты с нами или остаёшься в стороне? Решай быстрее!\n":
    {
      ru: "TON достанутся только лучшим! \nКорабль вернулся с добычей, здания полны ресурсов, а ТОП-50 уже почти заполнен. Ты с нами или остаёшься в стороне? Решай быстрее!\n",
      en: "TON is only for the best!  \nThe ship has returned with treasures, the buildings are brimming with resources, and the Top 50 is almost full. Are you with us or staying on the sidelines? Make up your mind quickly!",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      "pt-br": "",
    },
  "Кто не успел, тот опоздал. Ресурсы на острове накопились, а TON каждый день достаются только тем, кто в ТОП-50. Ты с ними? Заходи в игру прямо сейчас!":
    {
      ru: "Кто не успел, тот опоздал. Ресурсы на острове накопились, а TON каждый день достаются только тем, кто в ТОП-50. Ты с ними? Заходи в игру прямо сейчас!",
      en: "If you snooze, you lose. Resources on the island have accumulated, and TON is given daily only to those in the Top 50. Are you with them? Join the game now!",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      "pt-br": "",
    },
  "Все уже в игре, а ты? \nРесурсы готовы к сбору, корабль вернулся, а TON уносят те, кто попал в ТОП-50. Заходи в игру и докажи, что ты лучший!\n":
    {
      ru: "Все уже в игре, а ты? \nРесурсы готовы к сбору, корабль вернулся, а TON уносят те, кто попал в ТОП-50. Заходи в игру и докажи, что ты лучший!\n",
      en: "Everyone’s already playing, are you?  \nResources are ready to collect, the ship has returned, and TON is being claimed by those in the Top 50. Join the game and prove you’re the best!",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      "pt-br": "",
    },
  "Не зли попугая!\nКорабль с сокровищами ждёт, здания ломятся от ресурсов. Всё это твоё, если успеешь! А в ТОП-50 TON уже ждут лучших. Вопрос только, там будешь ты?":
    {
      ru: "Не зли попугая!\nКорабль с сокровищами ждёт, здания ломятся от ресурсов. Всё это твоё, если успеешь! А в ТОП-50 TON уже ждут лучших. Вопрос только, там будешь ты?",
      en: "Don’t anger the parrot!  \nThe treasure ship is waiting, buildings are bursting with resources. It’s all yours if you’re fast enough! TON awaits the best in the Top 50. The only question is, will you be there?",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      "pt-br": "",
    },
  "Собери больше всего золота и жемчуга в рамках игрового для и получи TON": {
    ru: "Собери больше всего золота и жемчуга в рамках игрового дня и получи TON",
    en: "Gather the most gold and pearls during the game day to earn TON.",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Каждый день в 17:00 UTC (через": {
    ru: "Каждый день в 17:00 UTC, через",
    en: "Every day at 17:00 UTC, (in:",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "Призовые места распределяются таким образом:": {
    ru: "Призовые места распределяются таким образом:",
    en: "Prize placements are distributed as follows:",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  История: {
    ru: "Статус",
    en: "Status",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  Баланс: {
    ru: "Баланс",
    en: "Balance",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  Сумма: {
    ru: "Сумма",
    en: "Amount",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  Дата: {
    ru: "Дата",
    en: "Date",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  Готово: {
    ru: "Готово",
    en: "Ready",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  Ожидание: {
    ru: "Ожидание",
    en: "Pending",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  Отклонено: {
    ru: "Отклонено",
    en: "Declined",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
  "награды TON будут переведены на ваш кошелёк, если вы попали в рейтинг. Вам останется только вывести их.":
    {
      ru: " награды TON будут переведены на ваш кошелёк, если вы попали в рейтинг. Вам останется только вывести их.",
      en: "TON rewards will be transferred to your wallet if you make it into the rankings. All that’s left is to withdraw them.",
      fr: "",
      es: "",
      "es-l": "",
      id: "",
      "pt-br": "",
    },
  "Лицензия Капитана": {
    ru: "Лицензия Капитана",
    en: "Captain License",
    fr: "",
    es: "",
    "es-l": "",
    id: "",
    "pt-br": "",
  },
};

export const LANGUAGE = "Language";

export const getTranslation = (
  key: string,
  language?: keyof typeof LANGUAGES
): string => {
  const lang =
    language || (getLocalStorageItem(LANGUAGE) as keyof typeof LANGUAGES);
  return translations[key]?.[lang] || key;
};

export const getTranslationPhoto = (
  image: StaticImageData
): StaticImageData => {
  const lang = getLocalStorageItem(LANGUAGE);

  switch (image) {
    case tg:
      switch (lang) {
        case LANGUAGES.ru:
          return tg;
        case LANGUAGES.en:
          return tg_en;
        case LANGUAGES.fr:
          return tg_fr;
        case LANGUAGES.es:
          return tg_es;
        case LANGUAGES["es-l"]:
          return tg_es;
        case LANGUAGES.id:
          return tg_id;
        case LANGUAGES["pt-br"]:
          return tg_pt;
        default:
          return tg;
      }
    case tgPremium:
      switch (lang) {
        case LANGUAGES.ru:
          return tgPremium;
        case LANGUAGES.en:
          return tgPremium_en;
        case LANGUAGES.fr:
          return tgPremium_fr;
        case LANGUAGES.es:
          return tgPremium_es;
        case LANGUAGES["es-l"]:
          return tgPremium_es;
        case LANGUAGES.id:
          return tgPremium_id;
        case LANGUAGES["pt-br"]:
          return tgPremium_pt;
        default:
          return tgPremium;
      }
    case islandSoon:
      switch (lang) {
        case LANGUAGES.ru:
          return islandSoon;
        case LANGUAGES.en:
          return islandSoon_en;
        case LANGUAGES.fr:
          return islandSoon_fr;
        case LANGUAGES.es:
          return islandSoon_es;
        case LANGUAGES["es-l"]:
          return islandSoon_es;
        case LANGUAGES.id:
          return islandSoon_id;
        case LANGUAGES["pt-br"]:
          return islandSoon_pt;
        default:
          return islandSoon;
      }
    case prizeFond:
      switch (lang) {
        case LANGUAGES.ru:
          return prizeFond;
        case LANGUAGES.en:
          return prizeFond_en;
        case LANGUAGES.fr:
          return prizeFond;
        case LANGUAGES.es:
          return prizeFond;
        case LANGUAGES["es-l"]:
          return prizeFond;
        case LANGUAGES.id:
          return prizeFond;
        case LANGUAGES["pt-br"]:
          return prizeFond;
        default:
          return prizeFond;
      }
    default:
      return tg;
  }
};

export const getLanguageBySlug = (lang: string): string => {
  switch (lang) {
    case LANGUAGES.en:
      return "EN";
    case LANGUAGES.ru:
      return "РУ";
    case LANGUAGES.fr:
      return "FR";
    case LANGUAGES.es:
      return "ES";
    case LANGUAGES["es-l"]:
      return "ES-L";
    case LANGUAGES.id:
      return "ID";
    case LANGUAGES["pt-br"]:
      return "PT";
    default:
      return "РУ";
  }
};
