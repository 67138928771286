import { createSelector, createSlice } from "@reduxjs/toolkit";
import { ILeader, IDailyLeaderboardState } from "./interface";
import { LEADERS } from "./leaderboard.mockData";

const initialState: IDailyLeaderboardState = {
  leaders: null,
  isLoading: false,
  pauseTimestamp: "",
  currentPage: "ЗОЛОТО",
  nextResetTime: null,
  isPausedLeaderboard: true,
  totalTonReward: null,
  tonRewardToday: null,
};

export const leaderboardSLice = createSlice({
  name: "leaderboard",
  initialState,
  reducers: {
    setDailyLeaderboard: (state, action) => {
      if (action.payload.calculating) {
        state.pauseTimestamp = action.payload?.timer;
        state.isPausedLeaderboard = true;
        return;
      }
      if (action.payload.results.length < 3) {
        state.leaders = LEADERS;
        return;
      }

      state.isPausedLeaderboard = false;
      state.nextResetTime = action.payload.next_reset_time;
      state.totalTonReward = action.payload.total_ton_reward;
      state.tonRewardToday = action.payload.ton_reward_today;
      state.leaders = action.payload.results;
    },

    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLeaders: (state, action) => {
      state.leaders = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

export const selectLeaders = (state: {
  dailyLeaderboard: IDailyLeaderboardState;
}): ILeader[] => {
  if (!state.dailyLeaderboard.leaders) return [];
  return state.dailyLeaderboard.leaders;
};

export const selectWinners = createSelector([selectLeaders], (leaders) => {
  const winners = leaders.slice(1, 4);
  const reorderedWinners = [winners[1], winners[0], winners[2]];
  return reorderedWinners;
});

export const selectNominators = createSelector([selectLeaders], (leaders) => {
  return leaders.slice(4, leaders.length).filter((leader) => {
    return leader.position !== 999999;
  });
});

export const selectPerson = createSelector([selectLeaders], (leaders) => {
  const person = leaders[0];

  if (
    !leaders
      .slice(1, leaders.length)
      .filter((person) => person.position != 999999)
      .some((nominator) => nominator.id === person.id)
  ) {
    return {
      person,
      isPersonInNominators: false,
    };
  }

  const position = (
    leaders.findIndex((leader) => leader.id === person.id) + 1
  ).toString();

  return {
    person,
    position,
    isPersonInNominators: true,
  };
});

export const { setLeaders, setIsLoading, setDailyLeaderboard, setCurrentPage } =
  leaderboardSLice.actions;
export default leaderboardSLice.reducer;
