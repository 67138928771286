import {
  WebSocketsClientEvents,
  WebSocketsServerEvents,
} from "@/constants/events";
import { setWs, setWsOpened } from "@/store/sockets/soketsSlice";
import {
  increaseCoins,
  increasePearls,
  setAdress,
  setBoosters,
  setCanContinueMultiplier,
  setCheasts,
  setFirstEperience,
  setLicenseCapitanUser,
  setMapPiece,
  setStats,
  setSuperBooster,
  setWallet,
  updateBalance,
} from "@/store/user/userSlice";
import { Dispatch } from "redux";
import {
  processData,
  startBoosterTimer,
  startHouseTimers,
} from "./houses/processData";
import { setHouses, setIsTraveling } from "@/store/island/islandSlice";
import { ILaunchDTO } from "@/interfaces/DTO";
import { ACTIONS, getPopUpDataByNames } from "./getters/getPopUpDataByName";
import { Leagues, ModalFunction } from "@/constants/types";
import { WS_ERRORS } from "@/constants/errors";
import { setQuests, updateQuest } from "@/store/quests/questsSlice";
import { IValue } from "@/hooks/usePopUp";
import { setLeague } from "@/store/leaderboard/leaderboardSlice";
import { setLocalStorageItem } from "./localStorage";
import { LOCAL_STORAGE } from "@/constants/localStorage";
import { IPurchaseDTO } from "@/interfaces/interfaces";
import { getShopItemImage } from "./shop";
import { LEAGUES } from "@/constants";
import {
  setNotification,
  setPopup,
} from "@/store/notification/notificationSlice";
import { setDailyReward } from "@/store/dailyReward/dailyRewardSlice";
import { setAdsModal, setShowModalAds } from "@/store/ads/adsSlice";
import { setStarterPack } from "@/store/starterPack/starterPackSlice";
import {
  setExpeditions,
  setLicenseData,
} from "@/store/expedition/expeditionSlice";

export const createWs = (
  url: string,
  modal: ModalFunction,
  dispatch: Dispatch<any>
) => {
  try {
    const _ws = new WebSocket(url);

    _ws.onopen = (event: Event) => {
      if (event.type === "open") {
        dispatch(setWsOpened(true));
      }
    };

    let reconnectTimeout: NodeJS.Timeout | null = null;

    const reconnectWebSocket = () => {
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }

      reconnectTimeout = setTimeout(() => {
        if (_ws.OPEN !== WebSocket.OPEN) {
          _ws.close();
          const ws = createWs(url, modal, dispatch);
          dispatch(setWs(ws));
          dispatch(setWsOpened(true));
        }
      }, 1000);
    };

    _ws.onclose = () => {
      reconnectWebSocket();
    };

    _ws.onerror = (error) => {
      console.error(error);
      const { title, textButton, text, values, icon } = getPopUpDataByNames(
        ACTIONS.ERROR
      );
      modal(title, textButton, text, icon, values).then((answer: boolean) => {
        window.location.reload();
      });
      if (_ws.readyState !== WebSocket.CLOSED) {
        _ws.close();
      }
    };

    _ws.onmessage = (event: MessageEvent) => {
      try {
        const message = JSON.parse(event.data);
        handleWsMessage(message, dispatch, _ws, modal);
      } catch (e) {
        console.error(e);
        throw new Error("Ошибка обработки события: ");
      }
    };

    return _ws;
  } catch (error) {
    throw new Error(WS_ERRORS.NOT_FOUND);
  }
};

const handleWsMessage = (
  message: any,
  dispatch: Dispatch<any>,
  ws: WebSocket,
  modal: ModalFunction
) => {
  const { type } = message.data;

  switch (type) {
    case WebSocketsServerEvents.CONNECT:
      const { user, stats } = message.data as ILaunchDTO;
      const { ship, tasks } = user;
      const proccessedHouses = processData(user.houses);
      dispatch(setIsTraveling(ship.is_traveling));
      let correctLeague = LEAGUES[0];
      if (stats.league && LEAGUES.includes(stats.league as Leagues)) {
        correctLeague = stats.league as Leagues;
      } else {
        correctLeague = LEAGUES[0];
      }
      dispatch(setMapPiece(user.balance.map));
      dispatch(setLeague(correctLeague));
      setLocalStorageItem(LOCAL_STORAGE.LEAGUE, correctLeague);
      dispatch(setQuests(tasks));
      dispatch(increaseCoins(ship.resources_amount_gold));
      dispatch(increasePearls(ship.resources_amount_pearl));
      dispatch(setHouses(proccessedHouses));
      dispatch(startHouseTimers(proccessedHouses, ws));
      dispatch(updateBalance(user.balance));
      dispatch(setStats(stats));
      dispatch(
        setLicenseCapitanUser({
          license_capitan: user.license_capitan,
          license_capitan_expires: user.license_capitan_expires,
        })
      );
      dispatch(setAdress(user.wallet));
      dispatch(setFirstEperience(user.tutorial.first_experience));
      break;
    case WebSocketsServerEvents.UPDATE_CAPITAN_LICENSE:
      dispatch(
        setLicenseCapitanUser({
          license_capitan: message.data.purchased,
          license_capitan_expires: message.data.license_capitan_expires,
        })
      );
      break;
    case WebSocketsServerEvents.DAILY_REWARD:
      dispatch(
        setDailyReward({
          rewards: message.data.rewards,
          day_reward: message.data.day_reward,
        })
      );
    case WebSocketsServerEvents.UPDATE_BALANCE:
      dispatch(updateBalance(message.data));
      break;
    case WebSocketsServerEvents.PURCHASE:
      const transactions = message.data.transactions as IPurchaseDTO[];

      const transactionQueue = [...transactions];

      const showNextTransaction = () => {
        if (transactionQueue.length === 0) return;

        const transaction = transactionQueue.shift();

        if (!transaction && transactionQueue.length > 0)
          return showNextTransaction();

        if (!transaction) return;

        const cardTitle = transaction.title;
        const imageResponse = transaction.image;
        const bonus = transaction?.double_bonus;
        const image = getShopItemImage(imageResponse);
        const { title, textButton, text } = getPopUpDataByNames(ACTIONS.BUY);

        modal(
          title,
          textButton,
          text + cardTitle.toUpperCase() + "!",
          image,
          undefined,
          bonus
        ).then(() => {
          sendWsEvent(ws, WebSocketsClientEvents.PURCHASE_RECEIVED, {
            transaction_id: transaction?.tx_id,
            purchase_type: transaction?.purchase_type,
          });
          showNextTransaction();
        });
      };

      showNextTransaction();
      break;
    case WebSocketsServerEvents.HOUSE_BUILD_PROGRESS:
      break;
    case WebSocketsServerEvents.ACTUALIZED_STATS:
      dispatch(setStats(message.data));
      break;
    case WebSocketsServerEvents.HOUSE_UPDATE:
      const housese = processData(message.data.houses);
      dispatch(setHouses(housese));
      dispatch(startHouseTimers(housese, ws));
      break;
    case WebSocketsServerEvents.SHIP_TRAVEL_UPDATE:
      const { coins, pearls } = message.data.resources;
      if (coins > 0 || pearls > 0) {
        const { title, textButton, text } = getPopUpDataByNames(
          ACTIONS.SHIP_TRAVELED
        );
        const values: IValue = {
          textValue: "Награда:",
          values: { gold: coins, pearl: pearls },
        };
        modal(title, textButton, text, undefined, values);
      }
      const { is_traveling } = message.data;
      dispatch(setIsTraveling(is_traveling));
      break;
    case WebSocketsServerEvents.TASK_STATUS:
      dispatch(updateQuest(message.data));
      break;
    case WebSocketsServerEvents.CAPITAN_LICENSE_PURCHASE:
      dispatch(setLicenseData(message.data));
      break;
    case WebSocketsServerEvents.TASK_COMPLETED:
      break;
    case WebSocketsServerEvents.BUILD_UPGRADE:
      break;
    case WebSocketsServerEvents.RESOURCE_COLLECTED:
      break;
    case WebSocketsServerEvents.CHESTS_COLLECTED:
      const { chests } = message.data;
      dispatch(setCheasts(chests));
      break;
    case WebSocketsServerEvents.BOOSTER_UPDATE:
      dispatch(setBoosters(message.data));
      dispatch(
        setCanContinueMultiplier(
          message.data.multipliers_boosters.can_continue_booster
        )
      );
      dispatch(startBoosterTimer(message.data, ws));
      break;
    case WebSocketsServerEvents.STARTER_PACK:
      dispatch(setStarterPack(message.data));
      break;
    case WebSocketsServerEvents.POPUP:
      dispatch(setPopup(message.data));
      break;
    case WebSocketsClientEvents.BOOSTER_COLLECT:
      break;
    case WebSocketsClientEvents.SUPER_BOOSTER:
      dispatch(setSuperBooster(message.data));
      break;
    case WebSocketsServerEvents.SENT_EXPEDITION:
      dispatch(setExpeditions(message.data));
      break;
    case WebSocketsClientEvents.NOTIFICATION:
      dispatch(setNotification(message.data));
      break;
    case WebSocketsServerEvents.ADS:
      const { rewards, type_modal, count, provider_ads } = message.data;
      if (type_modal == "gift") {
        dispatch(setShowModalAds(true));
      }
      dispatch(
        setAdsModal({
          modal: {
            rewards: rewards,
            type: type_modal,
            count: count,
            provider_ads: provider_ads,
          },
        })
      );
      break;
    default:
      break;
  }
};

export const sendWsEvent = (
  ws: WebSocket | null,
  event: string,
  data?: Object
) => {
  if (!ws) throw new Error(WS_ERRORS.NOT_FOUND);
  ws.send(JSON.stringify({ type: event, data: data }));
};
